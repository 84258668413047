import React from "react";
import { FaCircleNotch } from 'react-icons/fa';
import {truncStringPortion} from '../../../../utils/formatter.js';

class Confirm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
    };
  }

  render = () => (
        <div className="confirm">
            <h3 className="tcenter">{this.props.amount? 'Confirm operation' : 'Confirm delegation'}</h3>
            <p className="tcenter">
                <small>
                    Please confirm all operation details
                </small>
            </p>
            <p className="tcenter">
                <strong>
                    Review and validate {this.props.amount? 'operation' : 'delegation'}
                </strong>
            </p>
            <div className="gauge">Fees & Gas ~{this.props.amount? this.props.selectedCurrency === 'ꜩ'? '0.0001': '0.0015' : '0.0001'}ꜩ</div>
            <div className="entry block">
                <div className="avatar">
                    <img src={`https://avatars.dicebear.com/api/avataaars/${this.props.selectedKeystore.address}.svg`} alt="avatar"/>
                </div>  
                <div className="content">
                    <p>
                        <small>From</small>
                        <br/>
                        <strong>{this.props.selectedKeystore.alias}</strong>
                        <br/>
                        <small>{truncStringPortion(this.props.selectedKeystore.address, 12, 6)}</small>
                    </p>
                </div>  
            </div> 
            {this.props.amount?
                <h3 className="tcenter bmargin">
                    <strong>{this.props.amount} {this.props.selectedCurrency}</strong>
                </h3>
            :
            <h3 className="tcenter bmargin">
                <strong><small>Delegation</small></strong>
            </h3>
            }
            <div className="entry block">
                <div className="avatar">
                    <img src={`https://avatars.dicebear.com/api/avataaars/${this.props.receiver}.svg`} alt="avatar"/>
                </div>  
                <div className="content">
                    <p>
                        <small>To</small>
                        <br/>
                        {this.props.keystores.map(k => k.address === this.props.receiver &&
                            <strong key="selected">{k.alias}<br/></strong>
                        )}
                        <small>{truncStringPortion(this.props.receiver, 12, 6)}</small>
                    </p>
                </div>  
            </div> 
            {this.props.pending?
                    <p className="tcenter tmargin">
                        <strong><FaCircleNotch className="spinner"/></strong> {this.props.selectedKeystore.type === 'file'? 'Processing transaction...' : 'Please follow intructions from your ledger...'}
                    </p>
                :
                <div>
                    <div className="submit dashboard" onClick={() => {this.props.sendTransfer()}}>
                        Confirm
                    </div>
                    <div className="cancel" onClick={() => {this.props.cancelOp()}}>
                        Cancel
                    </div>
                </div>
            }
        </div>
  );
}


export default Confirm;