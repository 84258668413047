import React from "react";
import {
    WalletClient,
    BeaconMessageType,
    BeaconErrorType,
    Serializer,
  } from "@airgap/beacon-sdk";
import * as tezos from 'conseiljs';
import {truncStringPortion} from '../../../../utils/formatter.js';
import { InMemorySigner } from '@taquito/signer';
import { TezosToolkit } from '@taquito/taquito';
  
const client = new WalletClient({ name: "Tezorus" });

class Connect extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
        qrCode: null,
        step: 0,
        connecting: false,
        deserialized: null,
        connected: false,
        pswd: null,
        operating: false,
        parameters: null,
        currentMsg: null,
        opHash: null,
        encryptedWallet: null,
        error: null,
        errorFile: false,
        success: false,
        published: false,
        fileName: null,
    };
    this.hiddenFileInput = React.createRef();
   }
  
   onFileHandler = (event) => {
      const reader = new FileReader();
      reader.addEventListener('load', event => {
          try{
              const data = event.target.result.split(';base64,').pop();
              const buff = new Buffer(data, 'base64');
              const jsonData = JSON.parse(buff.toString('ascii'));
              this.setState({
                  encryptedWallet: jsonData,
                  errorFile: false
              });
          }catch(error){
              console.log(error);
              this.setState({errorFile: true});
          }
      });
      this.setState({fileName: event.target.files[0].name});
      reader.readAsDataURL(event.target.files[0]);
   }
  
  handleUpload = (event) => {
      this.hiddenFileInput.current.click();
  };

  handleQr = (e) => {
      this.setState({qrCode: e.target.value})
  }

  handlePswd = (e) => {
      this.setState({pswd: e.target.value})
  }
  
  setQRcode = async(handshakeMessage) => {
    const deserialized = await new Serializer().deserialize(handshakeMessage);
    console.log(deserialized)
    this.setState({deserialized: deserialized, step: 2})
  }
  
  acceptConnection = async() => {
    try{
        this.setState({connecting: true})
        await client.init(); // Establish P2P connection
        client.addPeer(this.state.deserialized);
        console.log('connected')
        await client.connect(this.callbackConnect)
    }catch(e){
        console.log(e);
        this.setState({connecting: false, connected: false})
    }
  }
  
  callbackConnect = async(message, connectionContext) => {
    try{
      console.log("Callback")
      console.log(message)
      this.setState({opHash: null})
      let response = null
      if(this.state.pswd){
        const loadedWallet = await tezos.TezosFileWallet.loadWalletString(JSON.stringify(this.props.selectedKeystore.keystore), this.state.pswd);
        await this.setState({publicKey: loadedWallet.identities[0].publicKey})
      }
      if(message.type === BeaconMessageType.PermissionRequest && (this.props.selectedNetwork === message.network.type)){
            response = {
                type: BeaconMessageType.PermissionResponse,
                network: message.network, // Use the same network that the user requested
                scopes: message.scopes,
                id: message.id,
                publicKey:
                  this.state.publicKey,
            };
            console.log(`deserialized_${this.props.selectedKeystore.address}_${this.props.selectedNetwork}`)
            sessionStorage.setItem(`deserialized_${this.props.selectedKeystore.address}_${this.props.selectedNetwork}`, JSON.stringify({deserialized: this.state.deserialized, publicKey: this.state.publicKey}));
            this.props.setConnection(this.state.deserialized)
            client.respond(response);
      }else if(message.type === BeaconMessageType.SignPayloadRequest){
            response = {
                type: BeaconMessageType.SignPayloadResponse,
                id: message.id,
                signingType: message.signingType,
                signature: "edsig...",
            };
      }else if(message.type === BeaconMessageType.OperationRequest){
        this.setState({currentMsg: message})
      }else{
          console.log("undefined message type")
      }
      console.log('done')
      this.setState({connecting: false, connected: true, toConnect: false})
    }catch(e){
        console.log(e);
        client.respond({
                type: BeaconMessageType.Error,
                id: message.id,
                errorType: BeaconErrorType.ABORTED_ERROR,
        });
        this.setState({connecting: false, connected: false})
    }
  }

  validateOp = async() => {
    this.setState({operating: true, error: null})
    try{
        const loadedWallet = await tezos.TezosFileWallet.loadWalletString(JSON.stringify(this.props.selectedKeystore.keystore), this.state.pswd);
        const Tezos = new TezosToolkit(this.props.nodes[0] || `https://${this.props.selectedNetwork}.smartpy.io/`);
        Tezos.setProvider({ signer: await InMemorySigner.fromSecretKey(loadedWallet.identities[0].privateKey || loadedWallet.identities[0].secretKey) });
        let txs = []
        for(let op of this.state.currentMsg.operationDetails){
            if(op.parameters){
                txs.push({
                    kind: op.kind,
                    amount: op.amount,
                    mutez: true,
                    to: op.destination,
                    parameter: op.parameters 
                })
            }else{
                txs.push({
                    kind: op.kind,
                    amount: op.amount,
                    mutez: true,
                    to: op.destination,
                })
            }
        }
        const batch = await Tezos.wallet.batch(txs)
        const batchOp = await batch.send();
        console.log('Operation hash:', batchOp.opHash);
        this.setState({opHash: batchOp.opHash});
        await batchOp.confirmation();
        const response = {
            type: BeaconMessageType.OperationResponse,
            id: this.state.currentMsg.id,
            transactionHash: batchOp.opHash,
        };
        client.respond(response);
        this.setState({operating: false, currentMsg: null});
        this.props.fetchAccountInfo(this.props.selectedKeystore);
    }catch(e){
        console.log(e)
        this.setState({operating: false, error: 'Wrong password or parameters'})
    }
  }

  componentDidMount = async() => {
    console.log(this.props.selectedKeystore)
    console.log(`deserialized_${this.props.selectedKeystore.address}_${this.props.selectedNetwork}`)
    if(sessionStorage.getItem(`deserialized_${this.props.selectedKeystore.address}_${this.props.selectedNetwork}`)){
        const deserialized = JSON.parse(sessionStorage.getItem(`deserialized_${this.props.selectedKeystore.address}_${this.props.selectedNetwork}`))
        this.setState({deserialized: deserialized.deserialized, connected: true, publicKey: deserialized.publicKey})
        this.props.setConnection(deserialized.deserialized)
        await client.init(); // Establish P2P connection
        client.addPeer(deserialized.deserialized);
        console.log('connected')
        await client.connect(this.callbackConnect)
    }
  }

  closeConnection = async() => {
    const peers = await client.getPeers()
    console.log(peers)
    await client.removePermission(peers[0].id);
    await client.removePeer(peers[0])
    sessionStorage.removeItem(`deserialized_${this.props.selectedKeystore.address}_${this.props.selectedNetwork}`);
    window.location.reload();
  }

  render = () => (
        this.props.toConnect? 
        <React.Fragment>
            <div className="blur"></div>
            <div className="confirm connectbeacon">
                <h3 className="tcenter">Connect with Beacon</h3>
                {this.state.step === 0 && ! this.state.connected &&
                    <div>
                        <p className="tcenter">
                            <small>
                                Please set QRcode value from Beacon
                            </small>
                        </p>
                        {!this.props.selectedKeystore.keystore.ciphertext && this.props.selectedKeystore.type === 'file' &&
                            <div>
                                <input
                                    placeholder="Keystore file"
                                    type="file" className="dnone"
                                    ref={this.hiddenFileInput}
                                    onChange={(e) => this.onFileHandler(e)}
                                />
                                {!this.state.encryptedWallet?
                                    <div className="submit" onClick={this.handleUpload}>
                                        Upload wallet
                                    </div>
                                :
                                    <div>
                                        <p className="tcenter">
                                            <small className="tsuccess">
                                                <strong>{this.state.fileName}</strong> selected
                                            </small>
                                        </p>
                                        <p className="tcenter">
                                            <a className="pointer" href="#upload" onClick={() => {this.setState({encryptedWallet: null})}}>
                                                <small>Click to change file</small>
                                            </a>
                                        </p>
                                    </div>
                                }
                            </div>
                        }
                        <input type="text" placeholder="Paste the QRcode" onChange={this.handleQr}/>
                        <div>
                            <div className="submit dashboard" onClick={() => {this.setQRcode(this.state.qrCode)}}>
                                Connect
                            </div>
                            <div className="cancel" onClick={() => {this.props.displayConnect()}}>
                                Cancel
                            </div>
                        </div>
                    </div>
                }
                {this.state.step === 2 && !this.state.connected &&
                    <div>
                        <p className="tcenter">
                            <small>
                                Review connection
                            </small>
                        </p>
                        <div className="entry">
                            <div className="avatar">
                                <img src={this.state.deserialized.icon} alt="avatar"/>
                            </div>  
                            <div className="content">
                                <p>
                                    <strong>{this.state.deserialized.name}</strong>
                                    <br/>
                                    <small>{this.state.deserialized.id}</small>
                                </p>
                            </div> 
                            {this.props.selectedKeystore.type === 'file'
                                && <input type="password" placeholder="Password" onChange={this.handlePswd}/>}
                            {this.state.connecting?
                                <div className="badge-loading">Connecting...</div>
                            :
                                <div>
                                    <div className="submit dashboard" onClick={() => {this.acceptConnection()}}>
                                        Accept
                                    </div>
                                    <div className="cancel" onClick={() => {this.props.displayConnect()}}>
                                        Cancel
                                    </div>
                                </div> 
                            }
                        </div>
                    </div>
                }
                {this.state.connected &&
                    <div>
                        <p className="tcenter">
                            <small>
                                Connected to the following dApp
                            </small>
                        </p>
                        <hr/>
                        <div className="entry">
                            <div className="avatar">
                                <img src={this.state.deserialized.icon} alt="avatar"/>
                            </div>  
                            <div className="content">
                                <p>
                                    <strong>{this.state.deserialized.name}</strong>
                                    <br/>
                                    <small>{this.state.deserialized.id}</small>
                                    <br/>
                                    <small className="tinfo pointer" onClick={() => {this.closeConnection()}}>
                                        Disconnect
                                    </small>
                                </p>
                            </div> 
                            <div>
                                <div className="cancel" onClick={() => {this.props.displayConnect()}}>
                                    Close
                                </div>
                            </div> 
                        </div>
                    </div>
                }
            </div>
        </React.Fragment>
        :
        this.state.currentMsg?
            <React.Fragment>
                <div className="blur"></div>
                <div className="confirm connectbeacon">
                    <h3 className="tcenter">Review operation</h3>
                    <p className="tcenter">
                        <small>
                            Review all operations
                        </small>
                    </p>
                    {this.state.currentMsg.operationDetails.map((op, i) =>
                        <div className="entry block" key={`bl-${i}`}>
                            <div className="avatar">
                                <img src={`https://avatars.dicebear.com/api/avataaars/${op.destination}.svg`} alt="avatar"/>
                            </div>  
                            <div className="content">
                                <p>
                                    <small>To</small>
                                    <br/>
                                    <strong>Amount: {op.amount / 1000000}ꜩ<br/></strong>
                                    <small>{truncStringPortion(op.destination, 12, 6)}</small>
                                </p>
                            </div>    
                            {op.parameters && <div style={{overflowX: 'auto'}}>
                                    <p>
                                        <strong>{op.parameters.entrypoint}</strong>
                                        <br/>
                                        <small>
                                            {JSON.stringify(op.parameters.value)}
                                        </small>
                                    </p>
                            </div>}
                        </div>
                    )}
                    {this.state.operating?
                        <div className="badge-loading">Sending operation...</div>
                    :
                        <div>
                            {this.props.selectedKeystore.type === 'file'
                            && <input type="password" placeholder="Password" onChange={this.handlePswd}/>}
                            <div className="submit dashboard" onClick={() => {this.validateOp()}}>
                                Send operation
                            </div>
                            <div className="cancel">
                                Cancel operation
                            </div>
                        </div> 
                    }
                    {this.state.error && <p className="terror tcenter">{this.state.error}</p>}
                </div>
            </React.Fragment>
        :
            <div></div>
  );
}


export default Connect;