import React from "react";
import { FaIdBadge, FaLink, FaPenAlt, FaSignInAlt, FaWallet, FaTimesCircle, FaKey, FaUndo } from 'react-icons/fa';
import logo from '../../../assets/images/tezorus_logo.png';

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render = () => (
    this.props.display && <div className="selecter">
        <div className="closer" onClick={() => {this.props.displayMenu(false)}}><FaTimesCircle/></div>
        <div className="logo">
            <img src={logo} className="imglogo" alt="logo tezOrus"/>
        </div>
        {!this.props.logged &&
          <div>
            <h3>Account</h3>
            <p><small>Save your addresses & setup</small></p>
            <button className={this.props.filter === "signup"? "active" : "normal"} onClick={() => {this.props.updateFilter('signup')}}><FaPenAlt/> Sign up</button>
            <button className={this.props.filter === "login"? "active" : "normal"} onClick={() => {this.props.updateFilter('login')}}><FaSignInAlt/> Login</button>
          </div>
        }
        <h3>Wallets</h3>
        <p><small>No wallet? <a href="#create" onClick={() => {this.props.updateFilter('create')}}>Create a wallet</a></small></p>
        <button className={this.props.filter === "address"? "wenabler wactive" : "wenabler"} onClick={() => {this.props.updateFilter('address')}}><FaIdBadge/> Set address</button>
        <button className={this.props.filter === "private"? "wenabler wactive" : "wenabler"} onClick={() => {this.props.updateFilter('private')}}><FaKey/> Set private key</button>
        <button className={this.props.filter === "upload"? "wenabler wactive" : "wenabler"} onClick={() => {this.props.updateFilter('upload')}}><FaWallet/> Upload wallet</button>
        <button className={this.props.filter === "ledger"? "wenabler wactive" : "wenabler"} onClick={() => {this.props.updateFilter('ledger')}}><FaLink/> Connect Ledger</button>
        <button className={this.props.filter === "restore"? "wenabler wactive" : "wenabler"} onClick={() => {this.props.updateFilter('restore')}}><FaUndo/> Restore wallet</button>
        <p className="legal">
          <small><a href="https://codinsight.com/privacy-policy" target="_blank" rel="noreferrer">Privacy policy</a></small>
          <small> - </small>
          <small><a href="#legal" onClick={() => {this.props.updateFilter('legal')}}>Legal notice</a></small>
        </p>
    </div>
  );

}


export default Menu;