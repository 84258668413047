import React from "react";
import { FaCircleNotch } from 'react-icons/fa';
import { getJWT } from '../../../utils/user.js';
import { backEnd } from '../../../constants.js';

const axios = require('axios');

class Password extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
        newPassword: null,
        oldPassword: null,
        error: null,
        success: false,
        pending: false,
    };
  }

  updateNewPassword = (e) => {
    this.setState({newPassword: e.target.value});
  }

  updateOldPassword = (e) => {
    this.setState({oldPassword: e.target.value});
  }

  changePassword = async() => {
    this.setState({error: null, success: false, pending: true})  
    if(this.state.newPassword.length < 8)
        this.setState({error: 'Invalid password format. Must be 8 characters minimum'})    
    else{
        const jwt = await getJWT();
        console.log(jwt);
        console.log({current_password: this.state.oldPassword, password: this.state.newPassword});
        this._asyncRequest = axios.post(`${backEnd}account/password`, 
            {current_password: this.state.oldPassword, password: this.state.newPassword},
            {headers: {'X-AUTH-USER': jwt}}).then(
                resp => {
                    if(resp.data.status){
                        this.setState({success: true, pending: false});
                    }else{
                        this.setState({error: resp.data.error, pending: false});
                    }
                }
        ).catch(error => {
            console.log(error);
            this.setState({error: 'Could not update password. Wrong credentials.', pending: false});
        });
    }
  }

  render = () => (
        <div className="action extend overflow">
            <h3 className="tcenter">Change password</h3>
                    <p className="tcenter">
                        <small>
                            Set a new password
                        </small>
                    </p>
                    <p className="tcenter">
                        <strong>
                            Please fill all required information
                        </strong>
                    </p>
                    {this.state.error && <p className="terror tcenter">
                        <small>
                            {this.state.error}
                        </small>
                    </p>}
                    <input type="password" placeholder="Current password" onChange={this.updateOldPassword}/>
                    <input type="password" placeholder="New password" onChange={this.updateNewPassword}/>
                    {this.state.success?
                        <p className="tsuccess tcenter">
                            <strong>
                                Password successfully updated.
                            </strong>
                        </p>
                    :
                        this.state.pending?
                            <p className="tcenter"><strong><FaCircleNotch className="spinner"/></strong> <small>Processing. Please wait...</small></p>
                        :
                                <div className="submit" onClick={() => {this.changePassword()}}>
                                    Change password
                                </div>
                    }
                    <div className="cancel" onClick={() => {this.props.updateDisplay('profile')}}>
                        Back
                    </div>
        </div>
  );
}


export default Password;