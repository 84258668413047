import React from "react";
import { backEnd } from '../../../constants.js';
import { FaCircleNotch } from 'react-icons/fa';

const axios = require('axios');
// eslint-disable-next-line
const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/;
// eslint-disable-next-line
const mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})");

class Forgotten extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
        username: null,
        password: null,
        token: null,
        error: null,
        success: false,
        newPassword: false,
        pending: false,
    };
  }

  updateUsername = (e) => {
    this.setState({username: e.target.value});
  }

  updatePassword = (e) => {
    this.setState({password: e.target.value});
  }

  updateToken = (e) => {
    this.setState({token: e.target.value});
  }

  sendToken = () => {
    this.setState({error: null, success: false});
    if(!emailRegex.test(this.state.username)){
        this.setState({error: 'Invalid Email'});
    }else{
        this.setState({pending: true});
        this._asyncRequest = axios.post(`${backEnd}account/password/reset/token`, { email_address: this.state.username, app: "tezorus"}).then(
            res => {
                console.log(res);
                if(res.data && res.data.status){
                    this.setState({success: true, pending:false});
                }else if(res.data && !res.data.status){
                    this.setState({error: res.data.message, pending:false});
                }else{
                    this.setState({error: 'Email could not be send', pending:false});
                }
            }
        ).catch(error => {
            console.log(error);
            this.setState({error: 'Could not send email', pending: false});
        });
    }
  }

  resetPassword = () => {
    this.setState({error: null, newPassword: false});
    if(!this.props.token && !this.state.token)
        this.setState({error: 'Invalid token'});
    else if(!mediumRegex.test(this.state.password))
            this.setState({error: 'Invalid password'});
    else{
        this.setState({pending: true});
        console.log({password: this.state.password,
                     reset_token: this.props.token || this.state.token,
                     user_id: this.props.id,
                     email_address: this.state.username})
        console.log(`${backEnd}account/password/reset`)
        this._asyncRequest = axios.post(`${backEnd}account/password/reset`, {
                                password: this.state.password,
                                reset_token: this.props.token || this.state.token,
                                user_id: this.props.id,
                                email_address: this.state.username,
                                app: "tezorus"
                            }
                        ).then(
            res => {
                console.log(res);
                if(res.data && res.data.status){
                    this.setState({pending:false, newPassword: true});
                }else if(res.data && !res.data.status){
                    this.setState({error: res.data.error, pending:false});
                }else{
                    this.setState({error: 'Incorrect token', pending:false});
                }
            }
        ).catch(error => {
            console.log(error);
            this.setState({error: 'Could not reset password', pending: false});
        });
    }
  }


  render = () => (
        <div className="action">
        {!this.state.success && !this.props.id && !this.props.token &&
            <div>
                <div className="header">
                    <div className="access">
                        Forgotten password
                        <span>Get a reset Token to change your password</span>
                    </div>
                </div>
                <div className="cardAction">
                    <p className="tcenter">
                        <strong>
                            Set your email to receive a reset Token
                        </strong>
                    </p>
                    {this.state.error && <p className="terror tcenter">
                        <small>
                            {this.state.error}
                        </small>
                    </p>}
                    <input type="email" placeholder="Email" onChange={this.updateUsername}/>
                    {this.state.pending?
                            <p className="tcenter"><strong><FaCircleNotch className="spinner"/></strong> <small>Processing. Please wait...</small></p>
                        :
                            <div className="submit" onClick={() => {this.sendToken()}}>
                                Send token
                            </div>
                    }
                </div>
            </div>
        }
        {(this.state.success || (this.props.id && this.props.token)) &&
            <div>
                <div className="header">
                    <div className="access">
                    Reset password
                        <span>Set reset Token and change your password</span>
                    </div>
                </div>
                <div className="cardAction">
                    <p className="tcenter">
                        <strong>
                            Token sent to {this.state.username}
                        </strong>
                    </p>
                    {this.state.error && <p className="terror tcenter">
                        <small>
                            {this.state.error}
                        </small>
                    </p>}
                    <input type="text" placeholder="Token" onChange={this.updateToken} value={this.props.token || this.state.token}/>
                    <input type="password" placeholder="Password" onChange={this.updatePassword}/>
                    {this.state.newPassword?
                        <p className="tsuccess tcenter">
                            <strong>
                                Password successfully updated. You can now <a href="#login"  onClick={() => {this.props.updateFilter('login')}}>Login</a>
                            </strong>
                        </p>
                    :
                        this.state.pending?
                            <p className="tcenter"><strong><FaCircleNotch className="spinner"/></strong> <small>Processing. Please wait...</small></p>
                        :
                            <div className="submit" onClick={() => {this.resetPassword()}}>
                                Reset password
                            </div>
                    }
                </div>
            </div>
        }
        </div>
  );

}


export default Forgotten;