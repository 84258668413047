import React from "react";
import file from '../../../assets/images/file.svg';
import ledgerKey from '../../../assets/images/connect.svg';
import wallet from '../../../assets/images/wallet.svg';
import keystore from '../../../assets/images/keystore.svg';

const axios = require('axios');

class Base extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
        userIp: null,
    };
  }

  componentDidMount = async() => {
    const res = await axios.get('https://geolocation-db.com/json/')
    this.setState({userIp: res.data.IPv4})
  }



  render = () => (
        <div className="action">
            <div className="header">
                <div className="access">
                    Access your dashboard
                    <span>Select your option</span>
                </div>
            </div>
            <div className="card" onClick={() => {this.props.updateFilter('upload')}}>
                <img src={file} alt="Tezorus Logo"/>
                <p className="tcenter">
                    <small>No wallet?</small> <strong>Create and save your wallet</strong>
                </p>
                <p className="tcenter">
                    <small>Create a keystore to save your wallet</small>
                </p>
            </div>
            <div className="card" onClick={() => {this.props.updateFilter('upload')}}>
                <img src={keystore} alt="Tezorus Logo"/>
                <p className="tcenter">
                    <strong>Upload wallet</strong> <small>to unlock a keystore file</small>
                </p>
                <p className="tcenter">
                    <small>Browse and send assets</small>
                </p>
            </div>
            <div className="card" onClick={() => {this.props.updateFilter('ledger')}}>
                <img src={ledgerKey} alt="Ledger Logo"/>
                <p className="tcenter">
                    <strong>Connect Ledger</strong> <small>to unlock your Ledger Nano</small>
                </p>
                <p className="tcenter">
                    <small>Browse and send assets</small>
                </p>
            </div>
            <div className="card" onClick={() => {this.props.updateFilter('address')}}>
                <img src={wallet} alt="Wallet Logo"/>
                <p className="tcenter">
                    <strong>Set address</strong> <small>to browse your wallet's assets</small>
                </p>
                <p className="tcenter">
                    <small>Read only capabilities</small>
                </p>
            </div>
        </div>
  );

}


export default Base;