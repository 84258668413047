export const truncStringPortion = (str, firstCharCount = str.length, endCharCount = 0, dotCount = 3) => {
	if(str === undefined)
	    return str;
	let convertedStr="";
	convertedStr+=str.substring(0, firstCharCount);
	convertedStr += ".".repeat(dotCount);
	convertedStr+=str.substring(str.length-endCharCount, str.length);
	return convertedStr;
}

export const  truncateDesc = (str, n) => {
	return (str.length > n) ? str.substr(0, n-1) + '...' : str;
};

export const  getSortedData = (data, prop, isAsc) => {
    return data.sort((a, b) => {
        return (a[prop] < b[prop] ? -1 : 1) * (isAsc ? 1 : -1)
    });
}
