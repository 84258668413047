import React from "react";
import { FaCircleNotch } from 'react-icons/fa';
import Receivers from "./receivers.js";
import Confirm from "./confirm.js";
import Publishing from "./publishing.js";
import { TezosToolkit } from '@taquito/taquito';
import { LedgerSigner } from '@taquito/ledger-signer';
import TransportWebHID from '@ledgerhq/hw-transport-webhid';

class LedgerTransfer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
        selectedCurrency: 'ꜩ',
        activeContract: null,
        receiver: '',
        error: null,
        success: false,
        published: false,
        amount: 0,
        confirmation: false,
        listing: false,
        opHash: null,
        connecting: false,
        connected: false,
    };
  }

  switchCurrency = async (e) => {
    for(let c of this.props.contracts){
        if(c.token_metadata.symbol === e.target.value)
            this.setState({activeContract: c});
    }
    this.setState({selectedCurrency: e.target.value});
  }

  updateReceiver = (e) => {
    this.setState({receiver: e.target.value});
  }

  updateAmount = (e) => {
    this.setState({amount: e.target.value});
  }

  selectReceiver = (receiver) => {
    this.setState({listing: false, receiver: receiver});
  } 

  cancelOp = () => {
    this.setState({confirmation: false});
  } 

  buildTransfer = async() => {
    this.setState({error: null});
    if(!this.state.amount || this.state.amount === 0)
      this.setState({error: 'Invalid amount', pending: false});
    else if(!this.state.password && this.props.selectedKeystore.type === 'file')
      this.setState({error: 'No password', pending: false});
    else if(!this.state.receiver || this.state.receiver.length !== 36)
      this.setState({error: 'Invalid receiver', pending: false});
    else{
        this.setState({pending: true});
        try{
            this.setState({confirmation: true, pending: false});
        }catch(e){
            console.log(e);
            this.setState({error: 'Invalid password', pending: false});
        }
    }
  }

  sendTransfer = async() => {
    this.setState({pending: true});
    if(this.state.selectedCurrency === 'ꜩ')
        this.sendXTZ();
    else
        this.sendToken();
  }

  sendXTZ = async() =>{
    const Tezos = new TezosToolkit(this.props.nodes[0] || `https://${this.props.selectedNetwork}.smartpy.io/`);
    const transport = await TransportWebHID.create();
    try{
        const ledgerSigner = new LedgerSigner(transport);
        Tezos.setProvider({ signer: ledgerSigner });
        const resp = await Tezos.contract.transfer({ to: this.state.receiver, amount: this.state.amount });
        console.log(resp.hash);
        this.setState({success: true, error: null, pending: false, confirmation: false, opHash: resp.hash});
        await resp.confirmation();
        this.setState({published: true});
        await transport.close()
        setTimeout(() => {
            this.setState({success: false});
            this.props.updateDisplay('account');
            this.props.fetchAccountInfo(this.props.selectedKeystore);
        }, 2000);
    }catch(e){
        console.log(e);
        await transport.close()
        this.setState({pending: false, error: e.toString(), success: false, confirmation: false, published: false});
    }
  }

  sendToken = async() =>{
    const Tezos = new TezosToolkit(this.props.nodes[0] || `https://${this.props.selectedNetwork}.smartpy.io/`);
    const transport = await TransportWebHID.create();
    try{
        const ledgerSigner = new LedgerSigner(transport);
        Tezos.setProvider({ signer: ledgerSigner });
        const contract = await Tezos.contract.at(this.state.activeContract.contract);
        const amount = this.state.activeContract.token_metadata.decimals? this.state.amount * 10**this.state.activeContract.token_metadata.decimals : this.state.amount * 10**2;
        console.log({
            from_: this.props.selectedKeystore.address,
            txs: [
              {
                to_: this.state.receiver,
                token_id: this.state.activeContract.token_id,
                amount: amount,
              },
            ],
          })
        const resp = await contract.methods.transfer([{
            from_: this.props.selectedKeystore.address,
            txs: [
              {
                to_: this.state.receiver,
                token_id: this.state.activeContract.token_id,
                amount: amount,
              },
            ],
          }]).send()
        console.log(resp.hash);
        this.setState({success: true, error: null, pending: false, confirmation: false, opHash: resp.hash});
        await resp.confirmation();
        this.setState({published: true});
        await transport.close();
        setTimeout(() => {
            this.setState({success: false});
            this.props.updateDisplay('account');
            this.props.fetchAccountInfo(this.props.selectedKeystore);
        }, 2000);
    }catch(e){
        console.log(e);
        await transport.close();
        this.setState({pending: false, error: e.toString(), success: false, confirmation: false, published: false});
    }
  }

  render = () => (
        <div className="iblock">
            {(this.state.listing || this.state.confirmation || this.state.success) && 
                <div className="blur" onClick={() => {this.setState({listing: false, confirmation: false})}}>
                </div>
            }
            {this.state.listing && 
                <Receivers selectedKeystore={this.props.selectedKeystore}
                           keystores={this.props.keystores}
                           selectReceiver={this.selectReceiver}/>
            }
            <div className="action extend overflow">
                <h3 className="tcenter">Transfer funds</h3>
                <p className="tcenter">
                    <small>
                        Select currency, set amount and receiver to send funds
                    </small>
                </p>
                <p className="tcenter">
                    <strong>
                        Please fill all information to transfer funds
                    </strong>
                </p>
                {this.state.error && <p className="terror tcenter">
                    <small>
                        {this.state.error}
                    </small>
                </p>}

                <div className="parameters">
                    <input type="number" placeholder={`${this.state.selectedCurrency} amount`} className="amount" onChange={this.updateAmount} value={this.state.amount === 0? '' : this.state.amount} min={0}/>
                    <select className="cur" defaultValue={this.state.selectedCurrency} onChange={this.switchCurrency}>
                        <option value={'ꜩ'}>ꜩ</option>
                        {this.props.contracts.map(c => c.token_metadata.symbol?
                            <option key={c.token_metadata.symbol} value={c.token_metadata.symbol}>{c.token_metadata.symbol}</option>
                            :
                            <option key={c.token_metadata.name} value={c.token_metadata.name}>{c.token_metadata.name}</option>
                        )}
                    </select>
                </div>
                <p className="tcenter tnull">
                    <small>
                        Available funds: 
                        <strong className="lmargin">
                            {this.state.selectedCurrency === 'ꜩ'?
                                `${this.props.tzBalance} ꜩ`
                            :
                                this.props.contracts.map(c => (c.token_metadata.symbol === this.state.selectedCurrency || c.token_metadata.name === this.state.selectedCurrency) &&
                                    <span>
                                        {c.balance && c.balance.balance?
                                            c.token_metadata.decimals? parseInt(c.balance.balance) / Math.pow(10, c.token_metadata.decimals) : parseInt(c.balance.balance)
                                        :
                                            c.token_metadata.decimals? parseInt(c.balance) / Math.pow(10, c.token_metadata.decimals) : parseInt(c.balance) 
                                        } <small>{c.token_metadata.symbol}</small>
                                    </span>
                                )
                            }
                        </strong>
                    </small>
                </p>
                
                <p className="tcenter bnull tmargin">
                    <small>
                        <a href="#select" onClick={() => {this.setState({listing: true})}}>Select receiver</a> or set manually
                    </small>
                </p>
                <input type="text" placeholder="Receiver tz1, KT1..." className="tnull" onChange={this.updateReceiver} value={this.state.receiver}/>
                
                {this.state.connecting &&
                        <p className="tcenter"><strong><FaCircleNotch className="spinner"/></strong> <small>Please follow instructions from your ledger to connect...</small></p>
                }
                <div className="gauge">Fees ~{this.state.selectedCurrency === 'ꜩ'? '0.0001': '0.0015'}ꜩ</div>
                {this.state.pending?
                        <p className="tcenter"><strong><FaCircleNotch className="spinner"/></strong> <small>Building operation</small></p>
                    :
                    <div>
                        <div className="submit dashboard" onClick={() => {this.buildTransfer()}}>
                            Transfer funds
                        </div>
                        <div className="cancel" onClick={() => {this.setState({error: null, success: false, connected: false});this.props.updateDisplay('account');}}>
                            Cancel
                        </div>
                    </div>
                }
            </div>
            {this.state.confirmation &&
                <Confirm selectedKeystore={this.props.selectedKeystore}
                         amount={this.state.amount}
                         selectedCurrency={this.state.selectedCurrency}
                         receiver={this.state.receiver}
                         keystores={this.props.keystores}
                         cancelOp={this.cancelOp}
                         sendTransfer={this.sendTransfer}
                         pending={this.state.pending}
                         error={this.state.error}/>
            }
            {this.state.success &&
                <Publishing opHash={this.state.opHash} published={this.state.published}/>
            }
        </div>
  );
}


export default LedgerTransfer;