import React from "react";
import { FaCircleNotch } from 'react-icons/fa';
import {storeAuth} from '../../../utils/user.js';
import wallet from '../../../assets/images/wallet.svg';

class Address extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
        alias: null,
        address: null,
        success: false,
        error: null,
        processing: false,
    };
  }

  updateAddress = (e) => {
    this.setState({address: e.target.value});
  }

  updateAlias = (e) => {
    this.setState({alias: e.target.value});
  }

  connectDashboard = async() => {
    if(!this.state.address || this.state.address.length !== 36)
      this.setState({error: 'Invalid public address format'});
    else if(!this.state.alias || this.state.alias.length < 3)
      this.setState({error: 'Invalid wallet alias'});
    else{
      this.setState({processing: true, error: null});
      const keystoreAuth = {
          type: "public",
          address: this.state.address,
          keystore: {},
          alias: this.state.alias,
      }
      if(await storeAuth(keystoreAuth)){
          console.log(keystoreAuth)
          //this.saveWallet(keystoreAuth);
          window.location.href = '/dashboard';
      }else
          this.setState({processing: false, error: 'Could not save wallet. Limit reached!'});
    }
  }


  render = () => (
        <div className="action">
          <div className="header">
              <div className="access">
                Set public address
                <span>Set your Tezos address to access its information</span>
              </div>
          </div>
          <div className="cardAction">
            <p className="tcenter">
                <strong>
                    Please fill information to access dashboard
                </strong>
            </p>
            {this.state.error && <p className="terror tcenter">
                <small>
                    {this.state.error}
                </small>
            </p>}
            <div>
                <img className="icon" src={wallet} alt="Wallet Logo"/>
            </div>
            <input type="text" placeholder="tz1 Address" onChange={this.updateAddress}/>
            <input type="text" placeholder="Alias / wallet name" onChange={this.updateAlias}/>
            <p className="tcenter">
                <small className="policy">The dashboard will be read-only <br/> You will not be able to send transactions</small>
            </p>
            {this.state.pending?
                    <p className="tcenter"><strong><FaCircleNotch className="spinner"/></strong> <small>Connecting. Please wait...</small></p>
                :
                    <div className="submit dashboard" onClick={this.connectDashboard}>
                        Access dashboard
                    </div>
            }
          </div>
        </div>
  );

}


export default Address;