import React from "react";
import { FaTimes } from "react-icons/fa";
import { truncateDesc } from "../../../utils/formatter"

class NFT extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
    };
  }

  render = () => (
        <React.Fragment>
            <div className="blur"></div>
            <div className="token popnft">
                <div className="closing" onClick={this.props.hideNFT}><FaTimes/></div>
                {this.props.c.token.metadata &&
                    <div>
                            {this.props.c.token.metadata.thumbnail_uri ?
                                <img className="visual" src={`https://ipfs.io/ipfs/${this.props.c.token.metadata.thumbnail_uri.replace('ipfs://', '')}`}
                                     onClick={() => {window.open(`https://ipfs.io/ipfs/${this.props.c.token.metadata.artifactUri.replace('ipfs://', '')}`)}}/>
                            :
                            this.props.c.token.metadata.thumbnailUri ?
                                <img className="visual" src={`https://ipfs.io/ipfs/${this.props.c.token.metadata.thumbnailUri.replace('ipfs://', '')}`}
                                     onClick={() => {window.open(`https://ipfs.io/ipfs/${this.props.c.token.metadata.artifactUri.replace('ipfs://', '')}`)}}/>
                            :
                            this.props.c.token.metadata.display_uri ?
                                <img className="visual" src={`https://ipfs.io/ipfs/${this.props.c.token.metadata.display_uri.replace('ipfs://', '')}`}
                                     onClick={() => {window.open(`https://ipfs.io/ipfs/${this.props.c.token.metadata.artifactUri.replace('ipfs://', '')}`)}}/>
                            :
                            this.props.c.token.metadata.displayUri ?
                                <img className="visual" src={`https://ipfs.io/ipfs/${this.props.c.token.metadata.displayUri.replace('ipfs://', '')}`}
                                     onClick={() => {window.open(`https://ipfs.io/ipfs/${this.props.c.token.metadata.artifactUri.replace('ipfs://', '')}`)}}/>
                            :
                            this.props.c.token.metadata.artifactUri &&
                                <img className="visual" src={`https://ipfs.io/ipfs/${this.props.c.token.metadata.artifactUri.replace('ipfs://', '')}`}
                                     onClick={() => {window.open(`https://ipfs.io/ipfs/${this.props.c.token.metadata.artifactUri.replace('ipfs://', '')}`)}}/>
                            }
                        <div className="identity">
                            <span className="name">
                                {this.props.c.token.metadata.name}
                            </span>
                            <div className="badge">
                                    <strong>{parseInt(this.props.c.balance)}</strong>
                                    <small className="mleft">{this.props.c.token.metadata.symbol? this.props.c.token.metadata.symbol : parseInt(this.props.c.balance) > 1? 'items' : 'item'}</small>
                            </div>
                            <span className="desc">
                                {this.props.c.token.metadata.description}
                            </span>
                        </div>
                    </div>
                }
            </div>
        </React.Fragment>
  );

}


export default NFT;