import React from "react";
import { FaPenAlt, FaSignInAlt, FaPlusCircle, FaCopy, FaSignOutAlt, FaTimesCircle, FaCheckCircle, FaSync, FaLock, FaAt } from 'react-icons/fa';
import {truncStringPortion} from '../../../utils/formatter.js';
import {logOut} from '../../../utils/user.js';
import logo from '../../../assets/images/tezorus_logo.png';

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = { copied: false, };
  }

  updtKeystore = (e) =>{
    for(let k of this.props.keystores){
      if(k.address === e.target.value)
        this.props.fetchAccountInfo(k);
    }
  }

  processCopy = () =>{
    navigator.clipboard.writeText(this.props.selectedKeystore.address);
    this.setState({copied: true});
    setTimeout(
        function() {
            this.setState({copied: false});
        }
        .bind(this),
        2000
    );
  }

  render = () => (
    this.props.menu && <div className="selecter extend">
        <div className="closer" onClick={() => {this.props.displayMenu(false)}}><FaTimesCircle/></div>
        <div className="logo">
          <img src={logo} className="imglogo" alt="logo tezOrus"/>
        </div>
        <h3 className="pointer" onClick={() => {this.processCopy()}}>
          <small>{!this.state.copied? <FaCopy/> : <FaCheckCircle className="tsuccess"/>}</small> {this.props.selectedKeystore.address && truncStringPortion(this.props.selectedKeystore.address, 6, 6)}
        </h3>
        <p><strong>{this.props.selectedKeystore.alias}</strong> | <small>Type {this.props.selectedKeystore.type && this.props.selectedKeystore.type.toUpperCase()}</small></p>
        <p><small><a href="#sync" onClick={() => {this.props.fetchAccountInfo(this.props.selectedKeystore)}}><FaSync/> Resync</a></small></p>
        <hr/>
        <p><small>Switch wallet</small></p>
        <select onChange={this.updtKeystore} defaultValue={this.props.selectedKeystore.address}>
            {this.props.keystores.map(k =>
              <option key={k.alias} value={k.address}>{k.alias}</option>
            )}
        </select>

        <p><small>Switch network</small></p>
        <select onChange={this.props.switchNetwork}>
            {this.props.networks.map(network =>
              <option key={network} value={network} selected={this.props.selectedNetwork === network}>{network}</option>
            )}
        </select>
        
        <p><small>Add new wallet</small></p>
        <button onClick={() => {window.location.href='/?filter=address'}}><FaPlusCircle/> Add wallet</button>

        <p className="legal">
          <small><a href="https://codinsight.com/privacy-policy" target="_blank" rel="noreferrer">Privacy policy</a></small>
          <small> - </small>
          <small><a href="#create" onClick={() => {this.props.updateDisplay('legal')}}>Legal notice</a></small>
        </p>

        {sessionStorage.getItem('userTezorus')?
          <div className="myaccount">
            <img className="avatarholder" src={`https://avatars.dicebear.com/api/personas/${sessionStorage.getItem('userTezorus')}.svg`} alt="account avatar"/>
            <div className="username">
              {sessionStorage.getItem('userTezorus')}
              <br/>
              <small><a href="#profile"  onClick={async() => {await this.props.updateDisplay('profile')}}>View account</a></small>
            </div>
          </div>
          :
          <div>
            <p><small>Close session & quit</small></p>
            <button onClick={async() => {await logOut()}}><FaSignOutAlt/> Quit</button>
          </div>
        }
        {!this.props.logged && <div>
          <p><small>Save your addresses & setup</small></p>
          <button onClick={() => {window.location.href='/?filter=signup'}}><FaPenAlt/> Sign up</button>
          <button onClick={() => {window.location.href='/?filter=login'}}><FaSignInAlt/> Login</button>
          <hr/>
        </div>}
    </div>
  );

}


export default Menu;