import React from "react";
import * as tezos from 'conseiljs';
import {storeAuth} from '../../../utils/user.js';
import { FaCircleNotch } from 'react-icons/fa';
import keystore from '../../../assets/images/keystore.svg';

class Upload extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
        encryptedWallet: null,
        errorFile: false,
        fileName: null,
        alias: null,
        password: null,
        pending: false,
    };
    this.hiddenFileInput = React.createRef();
  }

  handleUpload = (event) => {
    this.hiddenFileInput.current.click();
  };

  updateAlias = (e) => {
    this.setState({alias: e.target.value});
  }

  updatePswd = (e) => {
    this.setState({password: e.target.value});
  }

  
  onFileHandler = (event) => {
    const reader = new FileReader();
    reader.addEventListener('load', event => {
        try{
            console.log(event.target.result)
            const data = event.target.result.split(';base64,').pop();
            const buff = new Buffer(data, 'base64');
            const jsonData = JSON.parse(buff.toString('ascii'));
            this.setState({
                encryptedWallet: jsonData,
                errorFile: false
            });
        }catch(error){
            console.log(error);
            this.setState({errorFile: true});
        }
    });
    this.setState({fileName: event.target.files[0].name});
    reader.readAsDataURL(event.target.files[0]);
  }

  unlockKeys = async () => {
    this.setState({error: null});
    if(!this.state.password)
      this.setState({error: 'No password'});
    else if(!this.state.alias || this.state.alias.length < 3)
      this.setState({error: 'Invalid wallet alias'});
    else if(this.state.encryptedWallet){
        this.setState({pending: true});
        try{
            console.log(JSON.stringify(this.state.encryptedWallet))
            const loadedWallet = await tezos.TezosFileWallet.loadWalletString(JSON.stringify(this.state.encryptedWallet), this.state.password);
            this.connectDashboard(loadedWallet);
        }catch(error){
            console.log(error);
            this.setState({error: 'Wrong file or password', pending: false});
        }
    }else{
        this.setState({error: 'No selected wallet'});
    }
  }

  connectDashboard = async(loadedWallet) => {
    if(!this.state.alias || this.state.alias.length < 3)
        this.setState({error: 'Invalid wallet name', pending: false});
    else{
        const keystoreAuth = {
            type: "file",
            address: loadedWallet.identities[0].publicKeyHash,
            keystore: this.state.encryptedWallet,
            alias: this.state.alias,
        }
        if(await storeAuth(keystoreAuth)){
            //this.saveWallet(keystoreAuth);
            window.location.href = '/dashboard';
        }else
            this.setState({processing: false, error: 'Unexpected error occurred'});
    }
  }

  render = () => (
        <div className="action">
            <div className="header">
                <div className="access">
                    Upload wallet
                <span>Upload and unlock your wallet file (.tezwallet)</span>
                </div>
            </div>
            <div className="cardAction">
                <p className="tcenter">
                    <strong>
                        Please fill all information to access dashboard
                    </strong>
                </p>
                {this.state.error && <p className="terror tcenter">
                    <small>
                        {this.state.error}
                    </small>
                </p>}
                {this.state.errorFile && <p className="terror tcenter">
                    <small>
                        Invalid file or format.
                    </small>
                </p>}
                <div>
                    <img className="icon" src={keystore} alt="Keystore Logo"/>
                </div>
                <input
                    placeholder="Keystore file"
                    type="file" className="dnone"
                    ref={this.hiddenFileInput}
                    onChange={(e) => this.onFileHandler(e)}
                />
                {!this.state.encryptedWallet?
                    <div className="submit" onClick={this.handleUpload}>
                        Upload wallet
                    </div>
                :
                    <div>
                        <p className="tcenter">
                            <small className="tsuccess">
                                <strong>{this.state.fileName}</strong> selected
                            </small>
                        </p>
                        <p className="tcenter">
                            <a className="pointer" href="#upload" onClick={() => {this.setState({encryptedWallet: null})}}>
                                <small>Click to change file</small>
                            </a>
                        </p>
                    </div>
                }
                <input type="password" placeholder="Password" onChange={this.updatePswd}/>
                <input type="text" placeholder="Alias / wallet name" onChange={this.updateAlias}/>
                <p className="tcenter">
                    <small className="policy">Select your wallet file and set your password to unlock it</small>
                </p>
                {this.state.pending?
                        <p className="tcenter"><strong><FaCircleNotch className="spinner"/></strong> <small>Connecting. Please wait...</small></p>
                    :
                        <div className="submit dashboard" onClick={() => {this.unlockKeys()}}>
                            Access dashboard
                        </div>
                }
            </div>
        </div>
  );

}


export default Upload;