import axios from "axios";
import React from "react";
import { FaAt, FaChevronLeft, FaChevronRight, FaLock, FaSignOutAlt, FaTimes } from "react-icons/fa";
import { backEnd } from "../../../constants";
import { getJWT, logOut } from "../../../utils/user";

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
        profile: null,
        walletAddresses: 0,
    };
  }

  componentDidMount = async() => {
    const jwtoken = await getJWT()
    const profile = await axios.get(`${backEnd}account/information`,
                    {headers: {'X-AUTH-USER': jwtoken}});
    this.setState({profile: profile.data.account_information, walletAddresses: profile.data.wallet_addresses.length})
    
  }

  deleteAccount = async() => {
    if(window.confirm('Deactivate account? You will automatically disconnect and your account will be fully deleted in 30 days'))
    {
        const jwtoken = await getJWT()
        await axios.delete(`${backEnd}account/delete`,
        {headers: {'X-AUTH-USER': jwtoken}})
    }
  }

  render = () => (
    <div className="action">
        <div className="header">
            <div className="access">
                <span><strong>Your account</strong></span>
                <span><small>View and update your info</small></span>
            </div> 
            {this.state.profile && <div className="myprofile">
                <img className="avatarholder" src={`https://avatars.dicebear.com/api/personas/${sessionStorage.getItem('userTezorus')}.svg`} alt="account avatar"/>
                <div className="username">
                    {this.state.profile.username}
                    <br/>
                    {this.state.profile.email_address}
                </div>
            </div>}
            <p>
                Set a new email
                <br/>
                <small>Update your email address.</small>
            </p>
            <button onClick={async() => {this.props.updateDisplay('email')}}><FaAt/> Update email</button>
            <p>
                Update your password
                <br/>
                <small>Set your current password to define a new one.</small>
            </p>
            <button onClick={async() => {this.props.updateDisplay('change')}}><FaLock/> New password</button>
            <p>
                Disconnect your account
                <br/>
                <small>You will be disconnected from dashboard and your session will be expired.</small>
            </p>
            <button onClick={logOut}><FaSignOutAlt/> Sign out</button>
            
            <div className="cancel" onClick={() => {this.props.updateDisplay('account')}} style={{margin: "20px 0"}}>
                <FaChevronLeft/> Back
            </div>

            <hr/>
            <p className="terror">
                Deactivate your account
                <br/>
                <small>Your account will deactivated. After 30 days it will be automatically deleted.</small>
            </p>
            <button onClick={this.deleteAccount}><FaTimes/> Deactivate account</button>
        </div>

    </div>
  );

}


export default Profile;