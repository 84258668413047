import React from "react";
import { TezosToolkit } from "@taquito/taquito";
import { InMemorySigner } from '@taquito/signer';
import * as tezos from 'conseiljs';
import Publishing from "../transfer/publishing.js";
import { FaCircleNotch } from 'react-icons/fa';

class Contract extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
        password: null,
        encryptedWallet: null,
        error: null,
        errorFile: false,
        success: false,
        published: false,
        fileName: null,
        confirmation: false,
        opHash: null,
        contractHash: '',
        entrypoints: null,
        selectedEntrypoint: null,
        parameters: null,
        selectedParameter: null,
        entries: {},
    };
    this.hiddenFileInput = React.createRef();
  }

  onFileHandler = (event) => {
    const reader = new FileReader();
    reader.addEventListener('load', event => {
        try{
            const data = event.target.result.split(';base64,').pop();
            const buff = new Buffer(data, 'base64');
            const jsonData = JSON.parse(buff.toString('ascii'));
            this.setState({
                encryptedWallet: jsonData,
                errorFile: false
            });
        }catch(error){
            console.log(error);
            this.setState({errorFile: true});
        }
    });
    this.setState({fileName: event.target.files[0].name});
    reader.readAsDataURL(event.target.files[0]);
  }

  handleUpload = (event) => {
    this.hiddenFileInput.current.click();
  }

  updatePswd = (e) => {
    this.setState({password: e.target.value});
  }

  cancelOp = () => {
    this.setState({confirmation: false});
  } 

  updateContractHash = (e) => {
    this.setState({contractHash: e.target.value});
  } 

  updateEntrypoint = (e) => {
    this.setState({selectedEntrypoint: e.target.value});
  } 

  fetchContract = async() => {
    try{
        console.log(`FETCH ${this.state.contractHash}`);
        this.setState({entrypoints: null, error: null, selectedEntrypoint: null});
        const Tezos = new TezosToolkit(this.props.nodes[0] || `https://${this.props.selectedNetwork}.smartpy.io/`);
        const contract = await Tezos.wallet.at(this.state.contractHash);
        this.setState({entrypoints: Object.keys(contract.methods), parameters: contract.parameterSchema.ExtractSchema()});
    }catch(e){
        this.setState({error: 'Contract not found'})
    }
  }

  updateEntrypoint = async(e) => {
    this.setState({selectedParameter: this.state.parameters[e.target.value], entries: {}, selectedEntrypoint: e.target.value});
  }

  updateEntry = (e, name) => {
    let entries = this.state.entries;
    entries[name] = e.target.value;
    this.setState({entries: entries});
  }

  sendOp = async() =>{
    this.setState({pending: true});
    try{
        const loadedWallet = await tezos.TezosFileWallet.loadWalletString(JSON.stringify(this.props.selectedKeystore.keystore.ciphertext? this.props.selectedKeystore.keystore : this.state.encryptedWallet), this.state.password);
        try{
            const Tezos = new TezosToolkit(this.props.nodes[0] || `https://${this.props.selectedNetwork}.smartpy.io/`);
            Tezos.setProvider({ signer: await InMemorySigner.fromSecretKey(loadedWallet.identities[0].privateKey || loadedWallet.identities[0].secretKey) });
            const contract = await Tezos.contract.at(this.state.contractHash);
            let params = [];
            for(let key of Object.keys(this.state.entries)){
                params.push(this.state.entries[key]);
            }
            console.log(this.state.selectedEntrypoint)
            const resp = await contract.methods[this.state.selectedEntrypoint](...params).send()
            console.log(resp.hash);
            this.setState({success: true, error: null, pending: false, opHash: resp.hash});
            await resp.confirmation();
            this.setState({published: true});
            setTimeout(() => {
                this.setState({success: false, published: false});
            }, 2000);
        }catch(e){
            console.log(e);
            this.setState({pending: false, error: e.message, success: false, published: false});
        }
    }catch(e){
        console.log(e);
        this.setState({pending: false, error: 'Could not load wallet. Wrong password.', success: false, published: false});
    }
  }

  render = () => (
        <div className="iblock">
            {(this.state.confirmation || this.state.success) && 
                <div className="blur" onClick={() => {this.setState({confirmation: false})}}>
                </div>
            }
            <div className="action extend overflow">
                <h3 className="tcenter">Call custom contract</h3>
                <p className="tcenter">
                    <small>
                        Set a KT1 contract address to access its features
                    </small>
                </p>
                <p className="tcenter">
                    <strong>
                        Please fill all required information
                    </strong>
                </p>
                {this.state.error && <p className="terror tcenter">
                    <small>
                        {this.state.error}
                    </small>
                </p>}
                <input type="text" placeholder="Contract address KT1..." className="tmargin" onChange={this.updateContractHash} value={this.state.contractHash}/>
                <div className="submit" onClick={() => {this.fetchContract()}}>
                    Fetch contract
                </div>
                {this.state.entrypoints && 
                    <div>
                        <p className="tcenter tmargin">
                            <small>
                                Select your entrypoint / feature
                            </small>
                        </p>
                        <select className="dblock" onChange={this.updateEntrypoint}>
                            {this.state.entrypoints.map(entry => !entry.includes('get') &&
                                <option key={entry} value={entry}>{entry}</option>
                            )}
                        </select>
                        {this.state.selectedParameter && 
                            <div>
                                {Object.keys(this.state.selectedParameter).map(entry =>
                                    <input type="text" placeholder={`${entry} (type ${this.state.selectedParameter[entry]})`} 
                                           className="tmargin" onChange={(e) => this.updateEntry(e, entry)}/>
                                )}

                                {!this.props.selectedKeystore.keystore.ciphertext && this.props.selectedKeystore.type === 'file' &&
                                    <div>
                                        <input
                                            placeholder="Keystore file"
                                            type="file" className="dnone"
                                            ref={this.hiddenFileInput}
                                            onChange={(e) => this.onFileHandler(e)}
                                        />
                                        {!this.state.encryptedWallet?
                                            <div className="submit" onClick={this.handleUpload}>
                                                Upload wallet
                                            </div>
                                        :
                                            <div>
                                                <p className="tcenter">
                                                    <small className="tsuccess">
                                                        <strong>{this.state.fileName}</strong> selected
                                                    </small>
                                                </p>
                                                <p className="tcenter">
                                                    <a className="pointer" href="#upload" onClick={() => {this.setState({encryptedWallet: null})}}>
                                                        <small>Click to change file</small>
                                                    </a>
                                                </p>
                                            </div>
                                        }
                                    </div>
                                }
                                <input type="password" placeholder="Password" onChange={this.updatePswd}/>
                                {this.state.pending?
                                    <p className="tcenter"><strong><FaCircleNotch className="spinner"/></strong> <small>Processing operation</small></p>
                                :
                                    <div className="submit dashboard" onClick={() => {this.sendOp()}}>
                                        Send operation
                                    </div>
                                }
                            </div>
                        }
                    </div>
                }
                <div className="cancel" onClick={() => {this.setState({error: null, success: false});this.props.updateDisplay('account');}}>
                    Cancel
                </div>
                {this.state.success &&
                    <Publishing opHash={this.state.opHash} published={this.state.published}/>
                }
            </div>
        </div>
  );
}


export default Contract;