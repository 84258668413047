import React from "react";

class Legal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
    };
  }

  render = () => (
    <div className="action">
            <div className="header">
                <div className="access">
                    Legal notice
                    <span>
                        The website www.app.tezorus.com is the property of Codinsight SAS. 
                        <br/>
                        Copyright <strong>@Codinsight</strong>. 2021
                    </span>
                </div>
            </div>
            <p className="tmargin lsafe">
                <strong>Website editor</strong><br/>
                <small>
                       Website editor is Codinsight, "société par actions simplifiée" SAS, a simplified joint-stock company, registered 907 943 088 to the RCS of Montpellier.
                       <br/>
                       Email : <strong>contact@codinsight.com</strong>
                       <br/>
                       <a href="https://www.codinsight.com/" target="_blank" rel="noreferrer">www.codinsight.com</a>
                </small>
            </p>
            <p className="tmargin lsafe">
                <strong>Website hosting</strong><br/>
                <small>
                    The website app.tezorus.com is hosted by the company :<br/>
                    AMAZON WEB SERVICES EMEA SARL<br/>
                    38 Avenue John F. Kennedy, L-1855 Luxembourg<br/>
                    +33 805101420
                    <br/>
                    <a href="https://www.aws.com/" target="_blank" rel="noreferrer">www.aws.com</a>
                </small>
            </p>
        </div>
  );

}


export default Legal;