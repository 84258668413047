import React from "react";
import {TezosMessageUtils, CryptoUtils} from 'conseiljs';
import { FaCircleNotch } from 'react-icons/fa';
import { InMemorySigner } from '@taquito/signer';
import {storeAuth} from '../../../utils/user.js';
import privatekey from '../../../assets/images/private_key.svg';

class PrivateKey extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
        alias: null,
        privatekey: null,
        pswd: null,
        success: false,
        error: null,
        processing: false,
    };
  }

  updatePrivatekey = (e) => {
    this.setState({privatekey: e.target.value});
  }

  updateAlias = (e) => {
    this.setState({alias: e.target.value});
  }

  updatePswd = (e) => {
    this.setState({pswd: e.target.value});
  }

  connectDashboard = async() => {
    if(!this.state.privatekey || this.state.privatekey.length !== 54)
      this.setState({error: 'Invalid public address format'});
    else if(!this.state.alias || this.state.alias.length < 3)
      this.setState({error: 'Invalid wallet alias'});
    else{
      this.setState({processing: true, error: null});
      const signer = new InMemorySigner(this.state.privatekey);
      const publicKey = await signer.publicKey();
      const publicKeyHash = await signer.publicKeyHash();
      const keystore = {
        publicKey: publicKey,
        privateKey: this.state.privatekey,
        publicKeyHash: publicKeyHash,
        seed: "",
        storeType: 0
      }
      // const newWallet = await tezos.TezosFileWallet.saveWallet(keystore.publicKeyHash, {identities: [keystore]}, this.state.pswd);
      const keys = JSON.stringify([keystore]);
      const salt = await CryptoUtils.generateSaltForPwHash();
      console.log(salt)
      let encryptedKeys = await CryptoUtils.encryptMessage(keys, this.state.password, salt);
      const encryptedWallet = {
          version: '1',
          salt: TezosMessageUtils.readBufferWithHint(salt, ''),
          ciphertext: TezosMessageUtils.readBufferWithHint(encryptedKeys, ''),
          kdf: 'Argon2'
      };
      const keystoreAuth = {
        type: "file",
        address: publicKeyHash,
        keystore: encryptedWallet,
        alias: this.state.alias,
      }
      if(await storeAuth(keystoreAuth)){
          // this.saveWallet(keystoreAuth);
          window.location.href = '/dashboard';
      }else
          this.setState({processing: false, error: 'Unexpected error occurred'});
    }
  }

  render = () => (
        <div className="action">
          <div className="header">
              <div className="access">
              Set private key
                  <span>Set your Tezos private key and password to secure operations</span>
              </div>
          </div>
          <div className="cardAction">
            <p className="tcenter">
                <strong>
                    Please fill all information to access dashboard
                </strong>
            </p>
            {this.state.error && <p className="terror tcenter">
                <small>
                    {this.state.error}
                </small>
            </p>}
            <div>
                <img className="icon" src={privatekey} alt="Tezos Logo"/>
            </div>
            <input type="text" placeholder="Private key edsk..." onChange={this.updatePrivatekey}/>
            <input type="text" placeholder="Alias / wallet name" onChange={this.updateAlias}/>
            <input type="password" placeholder="Password" onChange={this.updatePswd}/>
            <p className="tcenter">
                <small className="policy">Secure your private key with a password to protect the session.</small>
            </p>
            {this.state.pending?
                    <p className="tcenter"><strong><FaCircleNotch className="spinner"/></strong> <small>Connecting. Please wait...</small></p>
                :
                    <div className="submit dashboard" onClick={this.connectDashboard}>
                        Access dashboard
                    </div>
            }
          </div>
        </div>
  );

}


export default PrivateKey;