import React from "react";
import codinsight from '../../assets/images/poweredby_codinsight.png';
import Menu from './components/menu.js';
import Card from './components/card.js';
import Tokens from './components/tokens.js';
import KeystoreTransfer from './components/transfer/keystoreTransfer.js';
import LedgerTransfer from './components/transfer/ledgerTransfer.js';
import Contract from './components/contract/contract.js';
import Delegate from './components/transfer/delegate.js';
import DelegateLedger from './components/transfer/delegateLedger.js';
import Password from './components/password.js';
import Email from './components/email.js';
import Legal from './../legal/index';
import Connect from './components/beacon/connect'
import { TezosToolkit, compose } from '@taquito/taquito';
import { Tzip12Module, tzip12 } from "@taquito/tzip12";
import { tzip16 } from "@taquito/tzip16";
import { loadAuth, getJWT } from '../../utils/user.js';
import { FaCircleNotch, FaBars } from 'react-icons/fa';
import { backEnd } from '../../constants.js';
import Profile from "./components/profile";

const axios = require('axios');

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
        logged: false,
        imgProcessing: false,
        contracts: [],
        keystores: [],
        selectedKeystore: {},
        tzEur: 0,
        tzBalance: 0,
        display: 'account',
        processing: true,
        networks: ['mainnet', 'granadanet', 'florencenet'],
        selectedNetwork: sessionStorage.getItem('network') || 'mainnet',
        accountInfo: null,
        version: "0.1.0",
        nodes: [],
        menu: true,
        fetchingData: false,
        tokenMetadata: {},
        contractMetadata: {}, 
        toConnect: false,
        deserialized: null,
        connected: false,
    };
  }

  updateDisplay = (display) => {
      this.setState({display: display});
  }

  displayMenu = (display) => {
      this.setState({menu: display});
  }

  displayConnect = () => {
      this.setState({toConnect: !this.state.toConnect})
  }

  setConnection = (deserialized) => {
      this.setState({deserialized, connected: true})
  }

  switchNetwork = async (e) => {
    await this.setState({selectedNetwork: e.target.value});
    sessionStorage.setItem('network', e.target.value);
    await this.fetchConfig();
    this.fetchAccountInfo(this.state.selectedKeystore);
  }

  fetchAccountInfo = async(selectedKeystore) => {
    this.setState({selectedKeystore: selectedKeystore, processing: true});
    const Tezos = new TezosToolkit(`https://${this.state.selectedNetwork}.smartpy.io/`);
    try{
        const info = await axios.get(`https://api.${this.state.selectedNetwork}.tzkt.io/v1/accounts/${selectedKeystore.address}?metadata=true`);
        this.setState({accountInfo: info.data})
    }catch(e){
        console.log(e);
    }
    Tezos.addExtension(new Tzip12Module());
    Tezos.tz
            .getBalance(selectedKeystore.address)
            .then((balance) => this.setState({tzBalance: parseFloat(balance.toNumber() / 1000000).toFixed(4)}))
            .catch((error) => console.log(JSON.stringify(error)));
    const resp = await axios.get(`https://api.tzkt.io/v1/quotes/last`);
    this.setState({tzEur: resp && resp.data? resp.data.eur : 0});
    const tokens = await axios.get(`https://api.${this.state.selectedNetwork}.tzkt.io/v1/tokens/balances?account=${selectedKeystore.address}&limit=500`);
    console.log(JSON.stringify(tokens.data))
    await this.setState({contracts: [], processing: false, fetchingData: true})
    if(!tokens.data) this.setState({fetchingData: false})
    else this.setState({ contracts: tokens.data, fetchingData: false})
  }

  fetchConfig = async() => {
    const config = await axios.get(`${backEnd}tezos/${this.state.selectedNetwork}/tezorus/config`);
    await this.setState({nodes: config.data.nodes, networks: config.data.tezos_networks, 
                   version: config.data.tezorus_version});
  }

  componentDidMount = async() => {
    await this.fetchConfig();
    const keystores = loadAuth();
    if(keystores){
        this.setState({keystores: keystores});
        this.fetchAccountInfo(keystores[0]);
        if(await getJWT()){
            this.setState({logged: true});
        }
    }else{
        window.location.href = "/";
    }
  }

  render = () => (
    <div className="welcome">
        <div className="board extend">
            <div className="bmenu" onClick={() => {this.displayMenu(true)}}><FaBars/></div>
            <Menu selectedKeystore={this.state.selectedKeystore} 
                  fetchAccountInfo={this.fetchAccountInfo} 
                  keystores={this.state.keystores} 
                  logged={this.state.logged}
                  selectedNetwork={this.state.selectedNetwork}
                  networks={this.state.networks}
                  menu={this.state.menu}
                  displayMenu={this.displayMenu}
                  updateDisplay={this.updateDisplay}
                  switchNetwork={this.switchNetwork}/>
            {this.state.display === "account" && 
                <div className="action extend overflow">
                {this.state.processing?
                    <FaCircleNotch className="maxi spinner"/>
                :
                    <div>
                        <Card selectedKeystore={this.state.selectedKeystore} 
                              tzBalance={this.state.tzBalance} 
                              tzEur={this.state.tzEur} 
                              updateDisplay={this.updateDisplay} 
                              accountInfo={this.state.accountInfo}
                              displayConnect={this.displayConnect}
                              deserialized={this.state.deserialized}
                              connected={this.state.connected}
                              selectedNetwork={this.state.selectedNetwork}/>
                        <Tokens contracts={this.state.contracts} 
                                fetchingData={this.state.fetchingData}
                                updateDisplay={this.updateDisplay}
                                walletType={this.state.selectedKeystore.type}/>
                    </div>
                }
                </div>
            }
            {this.state.display === "transfer" && this.state.selectedKeystore.type === 'file' &&
                <KeystoreTransfer contracts={this.state.contracts} 
                          updateDisplay={this.updateDisplay}
                          keystores={this.state.keystores}
                          selectedKeystore={this.state.selectedKeystore}
                          selectedNetwork={this.state.selectedNetwork}
                          fetchAccountInfo={this.fetchAccountInfo} 
                          nodes={this.state.nodes}
                          tzBalance={this.state.tzBalance}/>
            }
            {this.state.display === "transfer" && this.state.selectedKeystore.type === 'ledger' &&
                <LedgerTransfer contracts={this.state.contracts} 
                          updateDisplay={this.updateDisplay}
                          keystores={this.state.keystores}
                          selectedKeystore={this.state.selectedKeystore}
                          selectedNetwork={this.state.selectedNetwork}
                          fetchAccountInfo={this.fetchAccountInfo} 
                          nodes={this.state.nodes}
                          tzBalance={this.state.tzBalance}/>
            }
            {this.state.display === "contract" &&
                <Contract contracts={this.state.contracts} 
                          updateDisplay={this.updateDisplay}
                          keystores={this.state.keystores}
                          selectedKeystore={this.state.selectedKeystore}
                          selectedNetwork={this.state.selectedNetwork}
                          fetchAccountInfo={this.fetchAccountInfo} 
                          nodes={this.state.nodes}
                          tzBalance={this.state.tzBalance}/>
            }
            {this.state.display === "change" && 
                <Password updateDisplay={this.updateDisplay}/>
            }
            {this.state.display === "email" && 
                <Email updateDisplay={this.updateDisplay}/>
            }
            {this.state.display === "profile" && 
                <Profile updateDisplay={this.updateDisplay}/>
            }
            
            {this.state.filter === "legal" && <Legal  updateDisplay={this.updateDisplay}/>}
            {this.state.display === "delegation" && this.state.selectedKeystore.type === 'file' &&
                <Delegate contracts={this.state.contracts} 
                          updateDisplay={this.updateDisplay}
                          keystores={this.state.keystores}
                          selectedKeystore={this.state.selectedKeystore}
                          selectedNetwork={this.state.selectedNetwork}
                          fetchAccountInfo={this.fetchAccountInfo}  
                          nodes={this.state.nodes}
                          tzBalance={this.state.tzBalance}/>
            }
            {this.state.display === "delegation" && this.state.selectedKeystore.type === 'ledger' &&
                <DelegateLedger contracts={this.state.contracts} 
                          updateDisplay={this.updateDisplay}
                          keystores={this.state.keystores}
                          selectedKeystore={this.state.selectedKeystore}
                          selectedNetwork={this.state.selectedNetwork}
                          fetchAccountInfo={this.fetchAccountInfo}  
                          nodes={this.state.nodes}
                          tzBalance={this.state.tzBalance}/>
            }
            {this.state.display === "legal" && 
                <Legal updateDisplay={this.updateDisplay}/>
            }
            {this.state.selectedKeystore && this.state.selectedKeystore.type === 'file' && this.state.selectedNetwork &&
                <Connect displayConnect={this.displayConnect}
                        toConnect={this.state.toConnect}
                        selectedKeystore={this.state.selectedKeystore}
                        fetchAccountInfo={this.fetchAccountInfo}
                        nodes={this.state.nodes}
                        selectedNetwork={this.state.selectedNetwork}
                        setConnection={this.setConnection}/>}
        </div>
        <img className="poweredby" src={codinsight} alt="Codinsight" onClick={() => {window.open('https://codinsight.com')}}/>
    </div>
  );

}


export default Dashboard;