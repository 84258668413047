import React from "react";
import { FaCircleNotch } from 'react-icons/fa';
import { backEnd } from '../../../constants.js';

const axios = require('axios');

// eslint-disable-next-line
const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{10,})");
// eslint-disable-next-line
const mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})");
// eslint-disable-next-line
const weakRegex = new RegExp("^(?=.{4,})");
// eslint-disable-next-line
const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/;

class Signup extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
        checked: false,
        password: null,
        email: null,
        username: null,
        error: null,
        success: false,
        pending: false,
        userIp: null,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount = async() => {
    const res = await axios.get('https://geolocation-db.com/json/')
    this.setState({userIp: res.data.IPv4})
  }

  handleChange(checked) {
    this.setState({ checked });
  }

  updatePswd = (e) => {
    this.setState({password: e.target.value});
  }

  updateEmail = (e) => {
    this.setState({email: e.target.value});
  }

  updateUsername = (e) => {
    this.setState({username: e.target.value});
  }

  registerAccount = () => {
    this.setState({error: null, success: false});
    if(!this.state.email || !emailRegex.test(this.state.email)){
        this.setState({error: 'Invalid email address'});
    }else if(!this.state.password || !mediumRegex.test(this.state.password)){
        this.setState({error: 'Invalid password'});
    }else if(!this.state.username || this.state.username.length < 3){
        this.setState({error: 'Invalid Username'});
    }else if(!this.state.checked){
        this.setState({error: 'Privacy policy must be accepted to sign up'});
    }else{
        this.setState({pending: true});
        this._asyncRequest = axios.post(`${backEnd}account/register`, {
                                username: this.state.username,
                                email_address: this.state.email,
                                password: this.state.password
                            }
                        ).then(
            res => {
                //console.log(JSON.stringify(res));
                if(res.data){
                    this.setState({success: true, pending: false});
                }
            }
        ).catch(error => {
            console.log(error);
            this.setState({error: 'Could not register your account', pending: false});
        });
    }
  }



  render = () => (
        <div className="action">
            <div className="header">
                <div className="access">
                    Sign up
                    <span>Create your account to access, save & retrieve your addresses</span>
                </div>
            </div>
            <div className="cardAction">
                <p className="tcenter">
                    Note that <strong>tezOrus</strong> only saves your adresses and not your private keys or Wallets
                    <br/>
                    Please fill all information to create your account
                </p>
                <div>
                    <img className="avatargen" src={`https://avatars.dicebear.com/api/adventurer/${this.state.username || this.state.userIp || 'tezorus'}.svg`} alt="account avatar"/>
                    <p className="tcenter"><small>Generated avatar. Set username to update.</small></p>
                </div>
                <input type="text" placeholder="Username" onChange={this.updateUsername}/>
                <input type="email" placeholder="Email" onChange={this.updateEmail}/>
                <input type="password" placeholder="Password" onChange={this.updatePswd}/>
                
                {this.state.password && <div>
                    {strongRegex.test(this.state.password)?
                        <div className="gauge">Strong password</div>
                    :
                        mediumRegex.test(this.state.password)?
                            <div className="gauge" style={{backgroundColor: "#037fe4"}}>Medium password</div>
                        :
                                weakRegex.test(this.state.password) &&
                                    <div className="gauge" style={{backgroundColor: "#ff0000"}}>Weak password</div>
                    }
                </div>}
                <div className="tcenter">
                    <input type="checkbox" onChange={this.handleChange} checked={this.state.checked} />
                    <span className="policy">Accept <a href="https://codinsight.com/tezorus-terms" target="_blank" rel="noreferrer">Terms of Service</a></span>
                </div>
                {this.state.error && <p className="terror tcenter">
                    <small>
                        {this.state.error}
                    </small>
                </p>}
                <p className="dataprivacy">
                    <strong>Data privacy</strong><br/>
                    Personal data eventually collected through this form are necessary to contact you and handle your request.  
                    The data processing is based on your consent and you agree to be contacted by email. 
                    The recipient of your personal data is Codinsight SAS. 
                    Personal data will be kept during your relation with the company and no more than 3 years 
                    after the last exchange with us. 
                    To exercice your rights on your personal data, especially access, modification or erasure: <a href="https://codinsight.com/privacy-policy" target="_blank" rel="noreferrer">Privacy policy</a>
                </p>
                {this.state.pending?
                    <p className="tcenter"><strong><FaCircleNotch className="spinner"/></strong> <small>Processing registration. Please wait...</small></p>
                    :
                    this.state.success ? 
                        <p className="tsuccess tcenter">
                            <strong>
                                Account successfully created. You can now <a href="#login"  onClick={() => {this.props.updateFilter('login')}}>Login</a>
                            </strong>
                        </p>
                    :
                        <div className="submit" onClick={() => this.registerAccount()}>
                            Create account
                        </div>
                }
            </div>
        </div>
  );

}


export default Signup;