import React from "react";
import { FaCheckCircle, FaCircleNotch } from 'react-icons/fa';
import {truncStringPortion} from '../../../../utils/formatter.js';

class Publishing extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
    };
  }

  render = () => (
        <div className="confirm publish">
            <h3 className="tcenter">Awaiting validation</h3>
            {!this.props.published?
                <div>
                    <p className="tcenter">
                        <small>
                            Validation can take up to 1 min.
                        </small>
                    </p>
                    <p className="tcenter">
                        <strong>
                            Please wait until <br/>
                            {this.props.opHash && truncStringPortion(this.props.opHash, 12, 6)} validation
                        </strong>
                    </p>
                    <FaCircleNotch className="maxi spinner tmargin"/>
                </div>
            :
                <div>
                    <p className="tcenter">
                        <small>
                            Congratulations!
                        </small>
                    </p>
                    <p className="tcenter tsuccess">
                        <strong>
                            {this.props.opHash && truncStringPortion(this.props.opHash, 12, 6)} validated
                        </strong>
                    </p>
                    <FaCheckCircle className="validated tmargin"/>
                </div>
            }
        </div>
  );
}


export default Publishing;