import React from "react";
import logo from '../../assets/images/tezorus_logo.png';
import codinsight from '../../assets/images/poweredby_codinsight.png';
import { FaCookie, FaTimesCircle } from 'react-icons/fa';
import { backEnd } from '../../constants.js';

const axios = require('axios');

class Validate extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
        pending: true,
        success: false,
        error: null,
    };
  }

  componentDidMount = async() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const id = params.get('id');
    const token = params.get('token');
    console.log(`ID is ${id} | token is ${token}`)
    try{
        const res = await axios.get(`${backEnd}account/email/validate/${id}/${token}`);
        console.log(JSON.stringify(res))
        if(res.data){
            this.setState({error: null, pending: false, success: true})
        }else{
            this.setState({error: 'Error: unknown', pending: false, success: false})
        }
    }catch(e){
        this.setState({error: `Error: ${e.toString()}`, pending: false, success: false})
    }
  }

  render = () => (
    <div className="welcome">
        {!sessionStorage.getItem('userTezorus') && this.state.cookie && <div className="cookie">
            <FaCookie className="icon"/>
            We do not use <strong>any cookies</strong> for tezOrus to run. We value your privacy ;)
            <FaTimesCircle className="cross" onClick={() => {this.setState({cookie: false})}}/>
        </div>}
        <div className="header">
            <div className="logo">
                <img src={logo} className="imglogo" alt="logo tezOrus" onClick={() => {window.location.href='/'}}/>
                <div className="tagline">
                    Tezos web wallet
                </div>
            </div>
            {this.state.success && <div className="access">
                Thank you ;)
                <span>Your email has been successfully validated.</span>
            </div>}
            {this.state.error && <div className="access">
                :(
                <span>Email could not be validated ({this.state.error}).</span>
            </div>}
            {this.state.pending && <div className="access">
                ...
                <span>Please wait.</span>
            </div>}
        </div>
        <img className="poweredby" src={codinsight} alt="Codinsight" onClick={() => {window.open('https://codinsight.com')}}/>
    </div>
  );

}


export default Validate;