import React from "react";
import { FaCircleNotch } from 'react-icons/fa';
import { getJWT } from '../../../utils/user.js';
import { backEnd } from '../../../constants.js';

const axios = require('axios');

// eslint-disable-next-line
const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/;

class Email extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
        email: null,
        error: null,
        success: false,
        pending: false,
    };
  }

  updateEmail = (e) => {
    this.setState({email: e.target.value});
  }

  changeEmail = async() => {
    this.setState({error: null, success: false, pending: true})  
    if(!this.state.email || !emailRegex.test(this.state.email))
        this.setState({error: 'Invalid email format.'})    
    else{
        const jwt = await getJWT();
        console.log(jwt);
        console.log({current_email: this.state.oldEmail, email: this.state.newEmail});
        this._asyncRequest = axios.post(`${backEnd}account/email`, 
            {email_address: this.state.email},
            {headers: {'X-AUTH-USER': jwt}}).then(
                resp => {
                    if(resp.data.status){
                        this.setState({success: true, pending: false});
                    }else{
                        this.setState({error: resp.data.error, pending: false});
                    }
                }
        ).catch(error => {
            console.log(error);
            this.setState({error: 'Could not update email.', pending: false});
        });
    }
  }

  render = () => (
        <div className="action extend overflow">
            <h3 className="tcenter">Change email</h3>
                    <p className="tcenter">
                        <small>
                            Set a new email
                        </small>
                    </p>
                    <p className="tcenter">
                        <strong>
                            Please fill all required information
                        </strong>
                    </p>
                    {this.state.error && <p className="terror tcenter">
                        <small>
                            {this.state.error}
                        </small>
                    </p>}
                    <input type="email" placeholder="New email" onChange={this.updateEmail}/>
                    {this.state.success?
                        <p className="tsuccess tcenter">
                            <strong>
                                Email successfully updated.
                            </strong>
                        </p>
                    :
                        this.state.pending?
                            <p className="tcenter"><strong><FaCircleNotch className="spinner"/></strong> <small>Processing. Please wait...</small></p>
                        :
                            <div className="submit" onClick={() => {this.changeEmail()}}>
                                Change email
                            </div>
                    }
                    <div className="cancel" onClick={() => {this.props.updateDisplay('profile')}}>
                        Back
                    </div>
        </div>
  );
}


export default Email;