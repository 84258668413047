import { backEnd } from '../constants.js';

const axios = require('axios');

export const storeAuth = async(keystore) => {
    if (keystore) {
        if(sessionStorage.getItem('tezOrus')){
            let userStorage = JSON.parse(sessionStorage.getItem('tezOrus'));
            for (let [i, k] of userStorage.entries()) {
                if(k.address === keystore.address)
                    userStorage.splice(i, 1);
            }
            userStorage.push(keystore);
            sessionStorage.setItem('tezOrus', JSON.stringify(userStorage));
        }else{
            let keystores = [keystore];
            sessionStorage.setItem('tezOrus', JSON.stringify(keystores));
        }
        const jwtoken = await getJWT();
        if(jwtoken){
            try{
                console.log('SAVING WALLET');
                await axios.post(`${backEnd}account/wallet/address`, {
                        address: keystore.address,
                        alias: keystore.alias,
                        wallet_type: keystore.type,
                        blockchain: "tezos"
                    },
                    {headers: {'X-AUTH-USER': jwtoken}});
            }catch(error){
                console.log(error);
                return false;
            }
        }
        return true;
    } else {
        return false;
    }
}

export const loadAuth = () => {
    if(sessionStorage.getItem('tezOrus'))
        return JSON.parse(sessionStorage.getItem('tezOrus'));
    else
        return null;
}

export const rmAuth = async(address) => {
    let userStorage = JSON.parse(sessionStorage.getItem('tezOrus'));
    for (let [i, k] of userStorage.entries()) {
        if(k.address === address){
            userStorage.splice(i, 1);
            const jwtoken = await getJWT();
            if(jwtoken){
                try{
                    await axios.delete(`${backEnd}account/wallet/address/${address}`,
                        {headers: {'X-AUTH-USER': jwtoken}});
                }catch(error){
                    console.log(error);
                }
            }
        }
    }
    sessionStorage.setItem('tezOrus', JSON.stringify(userStorage));
    return true;
}

export const logOut = async() => {
    const refresh_token = sessionStorage.getItem('fabrickRefresh');
    try{
        await axios.get(`${backEnd}account/logout`,
            {headers: {'X-AUTH-USER': refresh_token}});
    }catch(e){
        console.log(e);
    }
    sessionStorage.removeItem('tezOrus');
    sessionStorage.removeItem('fabrickJWT');
    sessionStorage.removeItem('fabrickRefresh');
    sessionStorage.removeItem('userTezorus');
    sessionStorage.removeItem('lastFetch');
    window.location.reload();
}

export const signin = (jwt, refresh_token, username) => {
    sessionStorage.setItem('userTezorus', username);
    sessionStorage.setItem('fabrickRefresh', refresh_token);
    sessionStorage.setItem('fabrickJWT', jwt);
    sessionStorage.setItem('lastFetch', parseInt(new Date().getTime()/1000));
}

export const getJWT = async() => {
    if(sessionStorage.getItem('fabrickJWT')){
        try{
            if( parseInt(new Date().getTime()/1000) > (parseInt(sessionStorage.getItem('lastFetch')) + 500)){
                const refresh_token = sessionStorage.getItem('fabrickRefresh');
                const resp = await axios.get(`${backEnd}account/login/refresh`,
                {headers: {'X-AUTH-USER': refresh_token}});
                sessionStorage.setItem('fabrickJWT', resp.data.jwt_token);
                sessionStorage.setItem('lastFetch', new Date().getTime()/1000);
                return resp.data.jwt_token;
            }else
                return sessionStorage.getItem('fabrickJWT');
        }catch(e){
            console.log(e);
            await logOut();
        }
    }else
        return null;
}