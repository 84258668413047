import React from "react";
import {TezosMessageUtils, TezosWalletUtil, CryptoUtils} from 'conseiljs';
import file from '../../../assets/images/file.svg';

// eslint-disable-next-line
const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{10,})");
// eslint-disable-next-line
const mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})");
// eslint-disable-next-line
const weakRegex = new RegExp("^(?=.{4,})");
// eslint-disable-next-line

class Create extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        mnemonic: '',
        password: '',
        walletData: null,
        publicKeyHash: null,
        pending: false
    };
  }

  componentDidMount = () => {
    this.generateMnemonic();
  }

  changeMnemonic = (e) => {
    this.setState({mnemonic: e.target.value});
  }

  updatePswd = (e) => {
    this.setState({password: e.target.value});
  }

  generateMnemonic = () => {
    const mnemonic = TezosWalletUtil.generateMnemonic();
    this.setState({mnemonic: mnemonic});
  }

  generateKeys = async () => {
    this.setState({pending: true});
    const keystore = await TezosWalletUtil.unlockIdentityWithMnemonic(this.state.mnemonic, '');
    const keys = JSON.stringify([keystore]);
    const salt = await CryptoUtils.generateSaltForPwHash();
    console.log(salt)
    let encryptedKeys = await CryptoUtils.encryptMessage(keys, this.state.password, salt);
    const encryptedWallet = {
        version: '1',
        salt: TezosMessageUtils.readBufferWithHint(salt, ''),
        ciphertext: TezosMessageUtils.readBufferWithHint(encryptedKeys, ''),
        kdf: 'Argon2'
    };
    //const newWallet = await tezos.TezosFileWallet.saveWallet(keystore.publicKeyHash, {identities: [keystore]}, this.state.password);
    this.setState({
        walletData: "text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(encryptedWallet)),
        publicKeyHash: keystore.publicKeyHash,
        pending: false
    });
  }

  render = () => (
        <div className="action">
            <div className="header">
                <div className="access">
                    Create new wallet
                    <span>Generate a new secured keystore file to store your wallet key</span>
                </div>
            </div>
            <div className="cardAction">
                <div>
                    <img className="icon" src={file} alt="File Logo"/>
                </div>
                <p className="tcenter">
                    <strong>
                        Seed words
                    </strong>
                </p>
                <textarea value={this.state.mnemonic} onChange={this.changeMnemonic}></textarea>
                <p className="tcenter">
                    <small className="policy">Save in a <b>secure place</b> your seed words to restore your wallet</small>
                </p>
                <input type="password" placeholder="Password" onChange={this.updatePswd}/>
                {this.state.password && <div>
                    {strongRegex.test(this.state.password)?
                        <div className="gauge">Strong password</div>
                    :
                        mediumRegex.test(this.state.password)?
                            <div className="gauge" style={{backgroundColor: "#037fe4"}}>Medium password</div>
                        :
                                weakRegex.test(this.state.password) &&
                                    <div className="gauge" style={{backgroundColor: "#ff0000"}}>Weak password</div>
                    }
                </div>}
                <p className="tcenter">
                    <small className="policy">A strong password is min. 10 char, with numbers and special characters</small>
                </p>
                {this.state.walletData?
                    <div>
                        <p className="tcenter">
                            <a href={`data:${this.state.walletData}`} className="text-white bg-success p-2 rounded w-100 h-100"
                            download={`${this.state.publicKeyHash}.tezwallet`}>
                            <small>Download {this.state.publicKeyHash} wallet</small>
                            </a>
                        </p>
                    </div>
                    :
                    <div className="submit dashboard" onClick={() => {this.generateKeys()}}>
                        Create wallet
                    </div>
                }
            </div>
        </div>
  );

}


export default Create;