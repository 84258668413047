import React from "react";
import { truncateDesc } from "../../../utils/formatter"
import Token from '../../../assets/images/fa2token.png';
import NFT from "./nft";

class Tokens extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
        displayNft: null,
    };
  }

  hideNFT = () => {
    this.setState({displayNft: null})
  }

  render = () => (
        <div className="tokens">
            {this.props.fetchingData && <small>Fetching tokens & collectibles...</small> }
            <h3>
                Tokens
            </h3>
            {this.props.contracts.map((c, i) => c.token.metadata && (c.token.standard === "fa1.2" || !c.token.metadata.artifactUri) && (parseInt(c.balance) > 0) &&
                <div key={`c-${i}`} className="token">
                    <div className="identity">
                        {c.token.metadata.thumbnailUri && <div className="thumbnail">
                            <img src={`https://ipfs.io/ipfs/${c.token.metadata.thumbnailUri.replace('ipfs://', '')}`} />
                        </div>}
                        <div className="details">
                            <span className="name">
                                    <strong>{c.token.metadata.decimals? parseFloat(parseInt(c.balance) / (10**parseInt(c.token.metadata.decimals))).toFixed(4) : parseInt(c.balance)}</strong>
                                    <small className="mleft">{c.token.metadata.symbol? c.token.metadata.symbol : c.balance > 1? 'items' : 'item'}</small>
                            </span>
                            <span className="desc">
                                {c.token.metadata.name}
                            </span>
                        </div>
                    </div>
                </div>
            )}
            
            <h3>
                Collectibles {this.state.displayNft}
            </h3>
            {this.props.contracts.reverse().map((c, i) => c.token.metadata && c.token.standard === "fa2" && c.token.metadata.artifactUri && (parseInt(c.balance) > 0) &&
                <div key={`c-${i}`} className="token">
                    {c.token.metadata &&
                    <div>
                            {c.token.metadata.thumbnail_uri ?
                                <div className="nft" style={{backgroundImage: `url('https://ipfs.io/ipfs/${c.token.metadata.thumbnail_uri.replace('ipfs://', '')}')`}}
                                      onClick={() => this.setState({displayNft: i})}>
                                </div>
                            :
                            c.token.metadata.thumbnailUri ?
                                <div className="nft" style={{backgroundImage: `url('https://ipfs.io/ipfs/${c.token.metadata.thumbnailUri.replace('ipfs://', '')}')`}}
                                     onClick={() => this.setState({displayNft: i})}>
                                </div>
                            :
                            c.token.metadata.display_uri ?
                                <div className="nft" style={{backgroundImage: `url('https://ipfs.io/ipfs/${c.token.metadata.display_uri.replace('ipfs://', '')}')`}}
                                     onClick={() => this.setState({displayNft: i})}>
                                </div>
                            :
                            c.token.metadata.displayUri ?
                                <div className="nft" style={{backgroundImage: `url('https://ipfs.io/ipfs/${c.token.metadata.displayUri.replace('ipfs://', '')}')`}}
                                     onClick={() => this.setState({displayNft: i})}>
                                </div>
                            :
                            c.token.metadata.artifactUri &&
                                <div className="nft" style={{backgroundImage: `url('https://ipfs.io/ipfs/${c.token.metadata.artifactUri.replace('ipfs://', '')}')`}}
                                     onClick={() => this.setState({displayNft: i})}>
                                </div>
                            }
                        <div className="identity">
                            <span className="name">
                                {c.token.metadata.name}
                            </span>
                            <div className="badge">
                                    <strong>{parseInt(c.balance)}</strong>
                                    <small className="mleft">{c.token.metadata.symbol? c.token.metadata.symbol : parseInt(c.balance) > 1? 'items' : 'item'}</small>
                            </div>
                            <span className="desc">
                                {c.token.metadata.description? truncateDesc(c.token.metadata.description, 120) : '-'}
                            </span>
                        </div>
                    </div>
                    }
                    {this.state.displayNft === i && <NFT c={c} hideNFT={this.hideNFT}/>}
                </div>
            )}
        </div>
  );

}


export default Tokens;