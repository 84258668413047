import React from "react";
import codinsight from '../../assets/images/poweredby_codinsight.png';
import Menu from './components/menu.js';
import Signup from './components/signup.js';
import Login from './components/login.js';
import Address from './components/address.js';
import PrivateKey from './components/private';
import Upload from './components/upload.js';
import Ledger from './components/ledger.js';
import Create from './components/create.js';
import Restore from './components/restore.js';
import Forgotten from './components/forgotten';
import Base from './components/base';
import Legal from './../legal/index';
import { getJWT, loadAuth } from '../../utils/user.js';
import { FaBars, FaCookie, FaTimesCircle } from 'react-icons/fa';

class Welcome extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
        filter: "base",
        logged: false,
        active: false,
        display: true,
        cookie: true,
        id: null,
        token: null,
    };
  }

  componentDidMount = async() => {
    if(await getJWT()){
        this.setState({logged: true, filter: 'address'});
    }
    if(loadAuth()){
        this.setState({active: true});
    }
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const filter = params.get('filter');
    const id = params.get('id');
    const token = params.get('token');
    this.setState({id: id, token: token});
    if(filter && ['address', 'private', 'signup', 'login', 'create', 'upload', 'ledger', 'forgotten'].includes(filter))
        this.setState({filter: filter});

  }

  updateFilter = (filter) => {
    this.setState({filter: filter});
  }

  displayMenu = (display) => {
      this.setState({display: display});
  }

  render = () => (
    <div className="welcome">
        {!sessionStorage.getItem('userTezorus') && this.state.cookie && <div className="cookie">
            <FaCookie className="iconCookie"/>
            We do not use <strong>any cookies</strong> for tezOrus to run. We value your privacy ;)
            <FaTimesCircle className="cross" onClick={() => {this.setState({cookie: false})}}/>
        </div>}
        <div className="board">
            <div className="bmenu" onClick={() => {this.displayMenu(true)}}><FaBars/></div>
            {this.state.active && <div className="back" onClick={() => {window.location.href='/dashboard'}}>Back to dashboard</div>}
            <Menu filter={this.state.filter} 
                  updateFilter={this.updateFilter} 
                  logged={this.state.logged} 
                  display={this.state.display} 
                  displayMenu={this.displayMenu}/>
            {this.state.filter === "signup" && <Signup updateFilter={this.updateFilter}/>}
            {this.state.filter === "login" && <Login updateFilter={this.updateFilter}/>}
            {this.state.filter === "address" && <Address/>}
            {this.state.filter === "private" && <PrivateKey/>}
            {this.state.filter === "upload" && <Upload/>}
            {this.state.filter === "ledger" && <Ledger/>}
            {this.state.filter === "create" && <Create/>}
            {this.state.filter === "restore" && <Restore/>}
            {this.state.filter === "legal" && <Legal/>}
            {this.state.filter === "base" && <Base updateFilter={this.updateFilter}/>}
            {this.state.filter === "forgotten" && <Forgotten updateFilter={this.updateFilter} id={this.state.id} token={this.state.token}/>}
        </div>
        <img className="poweredby" src={codinsight} alt="Codinsight" onClick={() => {window.open('https://codinsight.com')}}/>
    </div>
  );

}


export default Welcome;