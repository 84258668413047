import React from "react";
import { FaCircleNotch } from 'react-icons/fa';
import Receivers from "./receivers.js";
import Confirm from "./confirm.js";
import Publishing from "./publishing.js";
import TransportWebHID from '@ledgerhq/hw-transport-webhid';
import { TezosToolkit } from '@taquito/taquito';
import { LedgerSigner } from '@taquito/ledger-signer';

class DelegateLedger extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
        password: null,
        receiver: '',
        error: null,
        success: false,
        published: false,
        confirmation: false,
        opHash: null,
    };
  }

  updateReceiver = (e) => {
    this.setState({receiver: e.target.value});
  }

  cancelOp = () => {
    this.setState({confirmation: false});
  } 

  buildDelegation = async() => {
    this.setState({error: null});
    if(!this.state.receiver || this.state.receiver.length !== 36)
      this.setState({error: 'Invalid baker', pending: false});
    else
        this.setState({confirmation: true, pending: false});
  }

  sendDelegation = async() => {
    this.setState({pending: true})
    const Tezos = new TezosToolkit(this.props.nodes[0] || `https://${this.props.selectedNetwork}.smartpy.io/`);
    const transport = await TransportWebHID.create();
    try{
        const ledgerSigner = new LedgerSigner(transport);
        Tezos.setProvider({ signer: ledgerSigner });
        const resp = await Tezos.contract.setDelegate({ source: await ledgerSigner.publicKeyHash(), delegate: this.state.receiver });
        console.log(resp.hash);
        this.setState({success: true, error: null, pending: false, confirmation: false, opHash: resp.hash});
        await resp.confirmation();
        this.setState({published: true});
        await transport.close();
        setTimeout(() => {
            this.setState({success: false});
            this.props.updateDisplay('account');
            this.props.fetchAccountInfo(this.props.selectedKeystore);
        }, 2000);
    }catch(e){
        console.log(e);
        await transport.close();
        this.setState({pending: false, error: e.toString(), success: false, confirmation: false, published: false});
    }
  }

  render = () => (
        <div className="iblock">
            {(this.state.listing || this.state.confirmation || this.state.success) && 
                <div className="blur" onClick={() => {this.setState({listing: false, confirmation: false})}}>
                </div>
            }
            {this.state.listing && 
                <Receivers selectedKeystore={this.props.selectedKeystore}
                           keystores={this.props.keystores}
                           selectReceiver={this.selectReceiver}/>
            }
            <div className="action extend overflow">
                <h3 className="tcenter">Delegate funds</h3>
                <p className="tcenter">
                    <small>
                        Set baker's address to delegate funds
                    </small>
                </p>
                <p className="tcenter">
                    <strong>
                        Please fill all information for delegation
                    </strong>
                </p>
                {this.state.error && <p className="terror tcenter">
                    <small>
                        {this.state.error}
                    </small>
                </p>}
                
                <p className="tcenter bnull tmargin">
                    <small>
                        Set baker address - Looking for one? <a href="https://baking-bad.org/" target="_blank" rel="noreferrer">Baking bad</a>
                    </small>
                </p>
                <input type="text" placeholder="Baker tz1, KT1..." className="tnull" onChange={this.updateReceiver} value={this.state.receiver}/>
                
                <div className="gauge">Fees ~0.0001ꜩ</div>
                {this.state.pending?
                        <p className="tcenter"><strong><FaCircleNotch className="spinner"/></strong> <small>Building delegation</small></p>
                    :
                    <div>
                        <div className="submit dashboard" onClick={() => {this.buildDelegation()}}>
                            Delegate funds
                        </div>
                        <div className="cancel" onClick={() => {this.props.updateDisplay('account')}}>
                            Cancel
                        </div>
                    </div>
                }
            </div>
            {this.state.confirmation &&
                <Confirm selectedKeystore={this.props.selectedKeystore}
                         selectedCurrency={this.state.selectedCurrency}
                         receiver={this.state.receiver}
                         keystores={this.props.keystores}
                         cancelOp={this.cancelOp}
                         sendTransfer={this.sendDelegation}
                         pending={this.state.pending}
                         error={this.state.error}/>
            }
            {this.state.success &&
                <Publishing opHash={this.state.opHash} published={this.state.published}/>
            }
        </div>
  );
}


export default DelegateLedger;