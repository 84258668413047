import React from "react";
import * as tezos from 'conseiljs';
import {storeAuth} from '../../../../utils/user.js';
import { FaCircleNotch } from 'react-icons/fa';
import Receivers from "./receivers.js";
import Confirm from "./confirm.js";
import Publishing from "./publishing.js";
import { InMemorySigner } from '@taquito/signer';
import { TezosToolkit } from '@taquito/taquito';
import pay from '../../../../assets/images/pay.svg';

class KeystoreTransfer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
        selectedCurrency: 'ꜩ',
        activeContract: null,
        password: null,
        receiver: '',
        encryptedWallet: null,
        error: null,
        errorFile: false,
        success: false,
        published: false,
        fileName: null,
        amount: 0,
        confirmation: false,
        listing: false,
        opHash: null,
    };
    this.hiddenFileInput = React.createRef();
  }

  onFileHandler = (event) => {
    const reader = new FileReader();
    reader.addEventListener('load', event => {
        try{
            const data = event.target.result.split(';base64,').pop();
            const buff = new Buffer(data, 'base64');
            const jsonData = JSON.parse(buff.toString('ascii'));
            this.setState({
                encryptedWallet: jsonData,
                errorFile: false
            });
        }catch(error){
            console.log(error);
            this.setState({errorFile: true});
        }
    });
    this.setState({fileName: event.target.files[0].name});
    reader.readAsDataURL(event.target.files[0]);
  }

  handleUpload = (event) => {
    this.hiddenFileInput.current.click();
  };

  switchCurrency = async (e) => {
    for(let c of this.props.contracts){
        if(c.token.metadata.symbol === e.target.value)
            this.setState({activeContract: c});
        else if(c.token.metadata.name === e.target.value)
            this.setState({activeContract: c});
    }
    this.setState({selectedCurrency: e.target.value});
  }

  updatePswd = (e) => {
    this.setState({password: e.target.value});
  }

  updateReceiver = (e) => {
    this.setState({receiver: e.target.value});
  }

  updateAmount = (e) => {
    this.setState({amount: e.target.value});
  }

  selectReceiver = (receiver) => {
    this.setState({listing: false, receiver: receiver});
  } 

  cancelOp = () => {
    this.setState({confirmation: false});
  } 

  buildTransfer = async() => {
    this.setState({error: null});
    if(!this.state.amount || this.state.amount === 0)
      this.setState({error: 'Invalid amount', pending: false});
    else if(!this.state.password && this.props.selectedKeystore.type === 'file')
      this.setState({error: 'No password', pending: false});
    else if(!this.state.receiver || this.state.receiver.length !== 36)
      this.setState({error: 'Invalid receiver', pending: false});
    else if(this.props.selectedKeystore.keystore.ciphertext || this.state.encryptedWallet){
        this.setState({pending: true});
        try{
            const loadedWallet = await tezos.TezosFileWallet.loadWalletString(JSON.stringify(this.props.selectedKeystore.keystore.ciphertext? this.props.selectedKeystore.keystore : this.state.encryptedWallet), this.state.password);
            if(this.state.encryptedWallet){
                const keystoreAuth = {
                    type: "file",
                    address: loadedWallet.identities[0].publicKeyHash,
                    keystore: this.state.encryptedWallet,
                    alias: this.props.selectedKeystore.alias,
                };
                await storeAuth(keystoreAuth);
            }else
                this.setState({encryptedWallet: this.props.selectedKeystore.keystore});
            this.setState({confirmation: true, pending: false});
        }catch(e){
            console.log(e);
            this.setState({error: 'Invalid password', pending: false});
        }
    }else{
        this.setState({error: 'No wallet could not be loaded', pending: false});
    }
  }

  sendTransfer = async() => {
    this.setState({pending: true});
    const loadedWallet = await tezos.TezosFileWallet.loadWalletString(JSON.stringify(this.state.encryptedWallet), this.state.password);
    if(this.state.selectedCurrency === 'ꜩ')
        this.sendXTZ(loadedWallet);
    else
        this.sendToken(loadedWallet);
  }

  sendXTZ = async(loadedWallet) =>{
    try{
        const Tezos = new TezosToolkit(this.props.nodes[0] || `https://${this.props.selectedNetwork}.smartpy.io/`);
        Tezos.setProvider({ signer: await InMemorySigner.fromSecretKey(loadedWallet.identities[0].privateKey || loadedWallet.identities[0].secretKey) });
        const resp = await Tezos.contract.transfer({ to: this.state.receiver, amount: this.state.amount });
        console.log(resp.hash);
        this.setState({success: true, error: null, pending: false, confirmation: false, opHash: resp.hash});
        await resp.confirmation();
        this.setState({published: true});
        setTimeout(() => {
            this.setState({success: false});
            this.props.updateDisplay('account');
            this.props.fetchAccountInfo(this.props.selectedKeystore);
        }, 2000);
    }catch(e){
        console.log(e);
        this.setState({pending: false, error: e.toString(), success: false, confirmation: false, published: false});
    }
  }

  sendToken = async(loadedWallet) =>{
    try{
        const Tezos = new TezosToolkit(this.props.nodes[0] || `https://${this.props.selectedNetwork}.smartpy.io/`);
        Tezos.setProvider({ signer: await InMemorySigner.fromSecretKey(loadedWallet.identities[0].privateKey || loadedWallet.identities[0].secretKey) });
        const contract = await Tezos.contract.at(this.state.activeContract.contract);
        const amount = this.state.activeContract.token.metadata.decimals? this.state.amount * 10**this.state.activeContract.token.metadata.decimals : this.state.amount;
        console.log(this.state.activeContract)
        console.log([{
            from_: loadedWallet.identities[0].publicKeyHash,
            txs: [
              {
                to_: this.state.receiver,
                token_id: this.state.activeContract.token_id,
                amount: amount,
              },
            ],
          }])
        const resp = await contract.methods.transfer([{
            from_: loadedWallet.identities[0].publicKeyHash,
            txs: [
              {
                to_: this.state.receiver,
                token_id: this.state.activeContract.token_id,
                amount: amount,
              },
            ],
          }]).send();
        console.log(resp.hash);
        this.setState({success: true, error: null, pending: false, confirmation: false, opHash: resp.hash});
        await resp.confirmation();
        this.setState({published: true});
        setTimeout(() => {
            this.setState({success: false});
            this.props.updateDisplay('account');
            this.props.fetchAccountInfo(this.props.selectedKeystore);
        }, 2000);
    }catch(e){
        console.log(e);
        this.setState({pending: false, error: e.message || JSON.stringify(e), success: false, confirmation: false, published: false});
    }
  }

  render = () => (
        <React.Fragment>
            {(this.state.listing || this.state.confirmation || this.state.success) && 
                <div className="blur" onClick={() => {this.setState({listing: false, confirmation: false})}}>
                </div>
            }
            {this.state.listing && 
                <Receivers selectedKeystore={this.props.selectedKeystore}
                           keystores={this.props.keystores}
                           selectReceiver={this.selectReceiver}/>
            }
            <div className="action extend overflow">
                <div className="header">
                    <div className="access">
                        Transfer funds
                        <span>Select currency, set amount and receiver to send funds</span>
                    </div>
                </div>
                <div className="cardAction">
                    <p className="tcenter">
                        <strong>
                            Please fill all information to transfer funds
                        </strong>
                    </p>
                    <div>
                        <img className="icon" src={pay} alt="Payment icon"/>
                    </div>
                    {this.state.error && <p className="terror tcenter">
                        <small>
                            {this.state.error}
                        </small>
                    </p>}
                    {this.state.errorFile && <p className="terror tcenter">
                        <small>
                            Invalid file or format.
                        </small>
                    </p>}
                    <div className="parameters">
                        <input type="number" placeholder={`${this.state.selectedCurrency} amount`} className="amount" onChange={this.updateAmount} value={this.state.amount === 0? '' : this.state.amount} min={0}/>
                        <select className="cur" defaultValue={this.state.selectedCurrency} onChange={this.switchCurrency}>
                            <option value={'ꜩ'}>ꜩ</option>
                            {this.props.contracts.map(c => c.token.metadata.symbol?
                                <option key={c.token.metadata.symbol} value={c.token.metadata.symbol}>{c.token.metadata.symbol}</option>
                                :
                                <option key={c.token.metadata.name} value={c.token.metadata.name}>{c.token.metadata.name}</option>
                            )}
                        </select>
                    </div>
                    <p className="tcenter tnull">
                        <small>
                            Available funds: 
                            <strong className="lmargin">
                                {this.state.selectedCurrency === 'ꜩ'?
                                    `${this.props.tzBalance} ꜩ`
                                :
                                    this.props.contracts.map(c => (c.token.metadata.symbol === this.state.selectedCurrency || c.token.metadata.name === this.state.selectedCurrency) &&
                                        <span>
                                            {c.balance && c.balance.balance?
                                                c.token.metadata.decimals? parseInt(c.balance.balance) / Math.pow(10, c.token.metadata.decimals) : parseInt(c.balance.balance)
                                            :
                                                c.token.metadata.decimals? parseInt(c.balance) / Math.pow(10, c.token.metadata.decimals) : parseInt(c.balance) 
                                            } <small>{c.token.metadata.symbol}</small>
                                        </span>
                                    )
                                }
                            </strong>
                        </small>
                    </p>
                    
                    <p className="tcenter bnull tmargin">
                        <small>
                            <a href="#select" onClick={() => {this.setState({listing: true})}}>Select receiver</a> or set manually
                        </small>
                    </p>
                    <input type="text" placeholder="Receiver tz1, KT1..." className="tnull" onChange={this.updateReceiver} value={this.state.receiver}/>
                    
                    {!this.props.selectedKeystore.keystore.ciphertext && this.props.selectedKeystore.type === 'file' &&
                        <div>
                            <input
                                placeholder="Keystore file"
                                type="file" className="dnone"
                                ref={this.hiddenFileInput}
                                onChange={(e) => this.onFileHandler(e)}
                            />
                            {!this.state.encryptedWallet?
                                <div className="submit" onClick={this.handleUpload}>
                                    Upload wallet
                                </div>
                            :
                                <div>
                                    <p className="tcenter">
                                        <small className="tsuccess">
                                            <strong>{this.state.fileName}</strong> selected
                                        </small>
                                    </p>
                                    <p className="tcenter">
                                        <a className="pointer" href="#upload" onClick={() => {this.setState({encryptedWallet: null})}}>
                                            <small>Click to change file</small>
                                        </a>
                                    </p>
                                </div>
                            }
                        </div>
                    }
                    <input type="password" placeholder="Password" onChange={this.updatePswd}/>
                    <div className="gauge">Fees ~{this.state.selectedCurrency === 'ꜩ'? '0.0001': '0.0015'}ꜩ</div>
                    {this.state.pending?
                            <p className="tcenter"><strong><FaCircleNotch className="spinner"/></strong> <small>Building operation</small></p>
                        :
                        <div>
                            <div className="submit dashboard" onClick={() => {this.buildTransfer()}}>
                                Transfer funds
                            </div>
                            <div className="cancel" onClick={() => {this.setState({error: null, success: false});this.props.updateDisplay('account');}}>
                                Cancel
                            </div>
                        </div>
                    }
                </div>
            </div>
            {this.state.confirmation &&
                <Confirm selectedKeystore={this.props.selectedKeystore}
                         amount={this.state.amount}
                         selectedCurrency={this.state.selectedCurrency}
                         receiver={this.state.receiver}
                         keystores={this.props.keystores}
                         cancelOp={this.cancelOp}
                         sendTransfer={this.sendTransfer}
                         pending={this.state.pending}
                         error={this.state.error}/>
            }
            {this.state.success &&
                <Publishing opHash={this.state.opHash} published={this.state.published}/>
            }
        </React.Fragment>
  );
}


export default KeystoreTransfer;