import React from "react";
import { signin, loadAuth, storeAuth } from '../../../utils/user.js';
import { FaCircleNotch } from 'react-icons/fa';
import { backEnd } from '../../../constants.js';
import { osVersion, osName, browserVersion, browserName } from "react-device-detect";

const axios = require('axios');

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
        password: null,
        username: null,
        error: null,
        success: false,
        pending: false,
        jwtoken: null,
        osVersion: null,
        osName: null,
        browserVersion: null,
        browserName: null,
    };
  };

  updatePswd = (e) => {
    this.setState({password: e.target.value});
  }

  updateUsername = (e) => {
    this.setState({username: e.target.value});
  }

  handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.loginAccount()
    }
  }

  loginAccount = async() => {
    this.setState({error: null, success: false});
    if(!this.state.password){
        this.setState({error: 'Invalid password'});
    }else if(!this.state.username || this.state.username.length < 3){
        this.setState({error: 'Invalid Username or Email'});
    }else{
        this.setState({pending: true});
        try{
            const res = await axios.post(`${backEnd}account/login`, {
                                    login: this.state.username,
                                    password: this.state.password,
                                    app: "tezorus",
                                    device: `${this.state.osName} ${this.state.osVersion} (${this.state.browserName} ${this.state.browserVersion})`,
                                });
            if(res.data){
                const jwtoken = res.data.jwt_token;
                const refresh_token = res.data.refresh_token;
                signin(jwtoken, refresh_token, res.data.account.username);
                const keystores = loadAuth();
                if(keystores){
                    for(let wallet of keystores){
                        try{
                            const resp = await axios.post(`${backEnd}account/wallet/address`, {
                                    address: wallet.address,
                                    alias: wallet.alias,
                                    wallet_type: wallet.type,
                                    blockchain: "tezos"
                                },
                                {headers: {'X-AUTH-USER': jwtoken}});
                            console.log(resp.data.message);
                        }catch(e){
                            console.log(e);
                        }
                        
                    }
                }
                const resp = await axios.get(`${backEnd}account/wallet/address`,
                                                {headers: {'X-AUTH-USER': jwtoken}});
                if(resp.data){
                    this.setState({success: true, pending: false});
                    console.log(resp.data);
                    for(let wallet of resp.data.wallets){
                        const keystoreAuth = {
                            type: wallet.wallet_type,
                            address: wallet.wallet_address,
                            keystore: {},
                            alias: wallet.alias,
                        }
                        await storeAuth(keystoreAuth);
                    }
                    window.location.href = '/dashboard';
                }
            }else   
                this.setState({error: 'Invalid Username or Email', pending: false});
        }catch(e){
            this.setState({error: 'Invalid Username or Email', pending: false});
        }
    }
  }

  componentDidMount = async() => {
      const res = await axios.get('https://geolocation-db.com/json/')
      this.setState({userIp: res.data.IPv4, osName: osName, osVersion: osVersion, browserName: browserName, browserVersion: browserVersion});
  }

  render = () => (
        <div className="action">
            <div className="header">
                <div className="access">
                    Login
                    <span>Access your account & saved addresses</span>
                </div>
            </div>
            <div className="cardAction">
                <p className="tcenter">
                    <strong>
                        Please fill information to login
                    </strong>
                </p>
                {this.state.error && <p className="terror tcenter">
                    <small>
                        {this.state.error}
                    </small>
                </p>}
                <div>
                    <img className="avatargen" src={`https://avatars.dicebear.com/api/adventurer/${this.state.username || this.state.userIp || 'tezorus'}.svg`} alt="account avatar"/>
                </div>
                <input type="text" placeholder="Email or Username" onChange={this.updateUsername}/>
                <input type="password" placeholder="Password" onChange={this.updatePswd} onKeyDown={this.handleKeyDown}/>
                <p className="tcenter">
                    <span className="policy"><a href="#forgotten"  onClick={() => {this.props.updateFilter('forgotten')}}>Forgotten password</a></span>
                </p>
                {this.state.pending?
                        <p className="tcenter"><strong><FaCircleNotch className="spinner"/></strong> <small>Connecting. Please wait...</small></p>
                    :
                        <div className="submit" onClick={this.loginAccount}>
                            Login
                        </div>
                }
                <p className="tcenter tmargin">
                    <small>Connecting from {this.state.osName} {this.state.osVersion} ({this.state.browserName} {this.state.browserVersion})</small>
                </p>
            </div>
        </div>
  );

}


export default Login;