import React from "react";
import logo from '../../../assets/images/tezorus_logo.png';
import { FaDownload, FaPaperPlane, FaList, FaTrashAlt, FaHandHolding, FaLink } from 'react-icons/fa';
import * as htmlToImage from 'html-to-image';
import {truncStringPortion} from '../../../utils/formatter.js';
import { rmAuth } from '../../../utils/user.js';

const QRCode = require('qrcode.react');

class Card extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
        imgProcessing: false,
        social: false,
    };
  }

  downloadCard = () => {
    this.setState({imgProcessing: true});
    var card = document.getElementById('card');
    htmlToImage.toPng(card)
    .then(dataUrl => {
        var a = document.createElement("a");
        a.href = dataUrl;
        a.setAttribute("download", `tezOrus_card_${this.props.selectedKeystore.address}`);
        a.click();
        this.setState({imgProcessing: false});
    })
    .catch(error => {
        console.error('oops, something went wrong!', error);
        this.setState({imgProcessing: false});
    });
  }

  rmWallet = async(address) =>{
      await rmAuth(address);
      window.location.reload();
  }

  render = () => (
        <div>
            <div className="header">
                <div className="access">
                    <span><strong>Account card</strong></span>
                    <span><small>Download your card | Share to receive funds</small></span>
                </div>
            </div>
            <div className="holder">
                <div id="card">
                    <div className="avatarbox">
                        <QRCode value={this.props.selectedKeystore.address} size={110} fgColor={'#fff'} bgColor={'rgba(0,0,0,0)'}/>
                    </div>
                    <div className="balance">
                        <img src={`https://avatars.dicebear.com/api/avataaars/${this.props.selectedKeystore.address}.svg`} className="minavatar"/>
                        <span className="nb">
                            <small>ꜩ</small> {parseFloat(this.props.tzBalance).toFixed(2)} <small className="currency"><small>€</small> {parseFloat(this.props.tzBalance * this.props.tzEur).toFixed(2)}</small>
                        </span>
                        <small className="network">Tezos - <strong>{this.props.selectedNetwork.toUpperCase()}</strong></small>
                    </div>
                    <img src={logo} className="tezorus" alt="logo tezOrus"/>
                    <small className="full">
                        {this.props.selectedKeystore.address}
                        {this.props.accountInfo && this.props.accountInfo.delegate &&
                            <small><br/>Delegate: {truncStringPortion(this.props.accountInfo.delegate.address, 12, 6)}</small>
                        }
                    </small>
                </div>
                {!this.state.imgProcessing && !this.state.social && <div className="downloader" onClick={this.downloadCard}><FaDownload/></div>}
                {!this.state.social && this.props.selectedKeystore.type === 'file' && <div className="beacon" onClick={() => {this.props.displayConnect()}}><FaLink/></div>}
                {this.props.selectedKeystore.type !== 'public'? 
                    <div className="actionbox">
                        <div className="entry" onClick={() => {this.props.updateDisplay('transfer')}}><FaPaperPlane/> <small>Send</small></div>
                        <div className="line"></div>
                        <div className="entry" onClick={() => {this.props.updateDisplay('delegation')}}><FaHandHolding/> <small>Delegate</small></div>
                        <div className="line"></div>
                        <div className="entry" onClick={() => {window.open(`https://${this.props.selectedNetwork}.tzkt.io/${this.props.selectedKeystore.address}/operations/`)}}>
                            <FaList/> <small>List operations</small>
                        </div>
                    </div>
                :
                    <div className="actionbox simplebox">
                        <div className="entry" onClick={() => {window.open(`https://${this.props.selectedNetwork}.tzkt.io/${this.props.selectedKeystore.address}/operations/`)}}>
                            <FaList/> <small>List operations</small>
                        </div>
                    </div>  
                }
                {!this.state.social && <div className="remove" onClick={() => {if(window.confirm('Remove this address?')){this.rmWallet(this.props.selectedKeystore.address)};}}><FaTrashAlt/></div>}
            </div>
            {this.props.connected && <p className="mTop tinfo"><small>Connected to <strong>{this.props.deserialized.name}</strong></small></p>}
        </div>
  );

}


export default Card;