import React from "react";
import './App.css';
import {BrowserRouter, Switch, Route} from "react-router-dom";
import Welcome from './pages/welcome/index.js';
import Dashboard from './pages/dashboard/index.js';
import Validate from './pages/validate/index.js';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  };

  render = () => (
    <BrowserRouter>
      <Switch>
        <Route path ="/" exact component={() => <Welcome/>} />
        <Route path ="/dashboard" exact component={() => <Dashboard/>} />
        <Route path ="/email/validate" exact component={() => <Validate/>} />
        <Route component={() => <Dashboard/>}/>
      </Switch>
    </BrowserRouter>
  );

}


export default App;
