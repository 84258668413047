import React from "react";
import {truncStringPortion} from '../../../../utils/formatter.js';

class Receivers extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
    };
  }

  render = () => (
        <div className="confirm">
            <h3 className="tcenter">Select receiver</h3>
            <p className="tcenter bmargin">
                <small>
                    Click on receiver to select it
                </small>
            </p>
            {this.props.keystores.map(k => k.address !== this.props.selectedKeystore.address &&
                <div key={`entry-${k.alias}`} className="entry" onClick={() => {this.props.selectReceiver(k.address)}}>
                    <div className="avatar">
                        <img src={`https://avatars.dicebear.com/api/avataaars/${k.address}.svg`} alt="avatar"/>
                    </div>  
                    <div className="content">
                        <p>
                            <strong>{k.alias}</strong>
                            <br/>
                            <small>{truncStringPortion(k.address, 12, 6)}</small>
                            <br/>
                            <small>Type <strong>{k.type.toUpperCase()}</strong></small>
                        </p>
                    </div>  
                </div>
            )}
        </div>
  );
}


export default Receivers;