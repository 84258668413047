import React from "react";
import { LedgerSigner } from '@taquito/ledger-signer';
import TransportWebHID from '@ledgerhq/hw-transport-webhid';
import {storeAuth} from '../../../utils/user.js';
import { FaCircleNotch } from 'react-icons/fa';
import connect from '../../../assets/images/connect.svg';

class Ledger extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
        pending: false,
        error: null,
        alias: null,
    };
  }

  updateAlias = (e) => {
    this.setState({alias: e.target.value});
  }

  connectLedger = async() => {
    if(!this.state.alias || this.state.alias.length < 3)
      this.setState({error: 'Invalid wallet alias'});
    else{
        this.setState({pending: true, error: null});
        try{
            const transport = await TransportWebHID.create();
            const ledgerSigner = new LedgerSigner(transport);
            const publicKeyHash = await ledgerSigner.publicKeyHash();
            const keystoreAuth = {
                type: "ledger",
                address: publicKeyHash,
                keystore: {},
                alias: this.state.alias,
            };
            await transport.close()
            if(await storeAuth(keystoreAuth))
                window.location.href = '/dashboard';
        }catch(e){
            console.log(e);
            this.setState({pending: false, error: 'Could not connect ledger'});
        }
    }
  }

  render = () => (
        <div className="action">
            <div className="header">
                <div className="access">
                    Connect Ledger
                <span>Connect your Ledger Nano S and click Connect</span>
                </div>
            </div>
            <div className="cardAction">
                <p className="tcenter">
                    <strong>
                        Please unlock your Ledger key and connect
                    </strong>
                </p>
                {this.state.error && <p className="terror tcenter">
                    <small>
                        {this.state.error}
                    </small>
                </p>}
                <div>
                    <img className="icon" src={connect} alt="Keystore Logo"/>
                </div>
                <input type="text" placeholder="Alias / wallet name" onChange={this.updateAlias}/>
                <p className="tcenter">
                    <small className="policy">1. Plug your ledger to your computer</small><br/>
                    <small className="policy">2. Unlock your ledger with Pin code</small><br/>
                    <small className="policy">3. Select the wallet to connect</small><br/>
                    <small className="policy">4. Click "Connect Ledger"</small>
                </p>
                {this.state.pending?
                    <p className="tcenter"><strong><FaCircleNotch className="spinner"/></strong> <small>Please follow instructions from your ledger to connect...</small></p>
                :
                    <div className="submit dashboard" onClick={() => {this.connectLedger()}}>
                        Connect Ledger
                    </div>
                }
            </div>
        </div>
  );

}


export default Ledger;