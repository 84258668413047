import React from "react";
import * as tezos from 'conseiljs';
import {storeAuth} from '../../../../utils/user.js';
import { FaCircleNotch } from 'react-icons/fa';
import Receivers from "./receivers.js";
import Confirm from "./confirm.js";
import Publishing from "./publishing.js";
import { InMemorySigner } from '@taquito/signer';
import { TezosToolkit } from '@taquito/taquito';
import delegate from '../../../../assets/images/delegate.svg';

class Delegate extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
        password: null,
        receiver: '',
        encryptedWallet: null,
        error: null,
        errorFile: false,
        success: false,
        published: false,
        fileName: null,
        confirmation: false,
        opHash: null,
    };
    this.hiddenFileInput = React.createRef();
  }

  onFileHandler = (event) => {
    const reader = new FileReader();
    reader.addEventListener('load', event => {
        try{
            const data = event.target.result.split(';base64,').pop();
            const buff = new Buffer(data, 'base64');
            const jsonData = JSON.parse(buff.toString('ascii'));
            this.setState({
                encryptedWallet: jsonData,
                errorFile: false
            });
        }catch(error){
            console.log(error);
            this.setState({errorFile: true});
        }
    });
    this.setState({fileName: event.target.files[0].name});
    reader.readAsDataURL(event.target.files[0]);
  }

  handleUpload = (event) => {
    this.hiddenFileInput.current.click();
  };

  updatePswd = (e) => {
    this.setState({password: e.target.value});
  }

  updateReceiver = (e) => {
    this.setState({receiver: e.target.value});
  }

  cancelOp = () => {
    this.setState({confirmation: false});
  } 

  buildDelegation = async() => {
    this.setState({error: null});
    if(!this.state.password)
      this.setState({error: 'No password', pending: false});
    else if(!this.state.receiver || this.state.receiver.length !== 36)
      this.setState({error: 'Invalid baker', pending: false});
    else if(this.props.selectedKeystore.keystore.ciphertext || this.state.encryptedWallet){
        this.setState({pending: true});
        try{
            const loadedWallet = await tezos.TezosFileWallet.loadWalletString(JSON.stringify(this.props.selectedKeystore.keystore.ciphertext? this.props.selectedKeystore.keystore : this.state.encryptedWallet), this.state.password);
            if(this.state.encryptedWallet){
                const keystoreAuth = {
                    type: "file",
                    address: loadedWallet.identities[0].publicKeyHash,
                    keystore: this.state.encryptedWallet,
                    alias: this.props.selectedKeystore.alias,
                };
                await storeAuth(keystoreAuth);
            }else
                this.setState({encryptedWallet: this.props.selectedKeystore.keystore});
            this.setState({confirmation: true, pending: false});
        }catch(e){
            console.log(e);
            this.setState({error: 'Invalid password', pending: false});
        }
    }else{
        this.setState({error: 'No wallet could not be loaded', pending: false});
    }
  }

  sendDelegation = async() => {
    try{
        this.setState({pending: true});
        const loadedWallet = await tezos.TezosFileWallet.loadWalletString(JSON.stringify(this.state.encryptedWallet), this.state.password);
        const Tezos = new TezosToolkit(this.props.nodes[0] || `https://${this.props.selectedNetwork}.smartpy.io/`);
        Tezos.setProvider({ signer: await InMemorySigner.fromSecretKey(loadedWallet.identities[0].privateKey || loadedWallet.identities[0].secretKey) });
        const resp = await Tezos.contract.setDelegate({ source: loadedWallet.identities[0].publicKeyHash, delegate: this.state.receiver });
        console.log(resp.hash);
        this.setState({success: true, error: null, pending: false, confirmation: false, opHash: resp.hash});
        await resp.confirmation();
        this.setState({published: true});
        setTimeout(() => {
            this.setState({success: false});
            this.props.updateDisplay('account');
            this.props.fetchAccountInfo(this.props.selectedKeystore);
        }, 2000);
    }catch(e){
        console.log(e);
        this.setState({pending: false, error: e.toString(), success: false, confirmation: false, published: false});
    }
  }

  render = () => (
        <React.Fragment>
            {(this.state.listing || this.state.confirmation || this.state.success) && 
                <div className="blur" onClick={() => {this.setState({listing: false, confirmation: false})}}>
                </div>
            }
            {this.state.listing && 
                <Receivers selectedKeystore={this.props.selectedKeystore}
                           keystores={this.props.keystores}
                           selectReceiver={this.selectReceiver}/>
            }
            <div className="action extend overflow">
                <div className="header">
                    <div className="access">
                        Delegate funds
                        <span>Set baker's address to delegate funds</span>
                    </div>
                </div>
                <div className="cardAction">
                    <p className="tcenter">
                        <strong>
                            Please fill all information for delegation
                        </strong>
                    </p>
                    <div>
                        <img className="icon" src={delegate} alt="Payment icon"/>
                    </div>
                    {this.state.error && <p className="terror tcenter">
                        <small>
                            {this.state.error}
                        </small>
                    </p>}
                    {this.state.errorFile && <p className="terror tcenter">
                        <small>
                            Invalid file or format.
                        </small>
                    </p>}
                    
                    <p className="tcenter bnull tmargin">
                        <small>
                            Set baker address - Looking for one? <a href="https://baking-bad.org/" target="_blank" rel="noreferrer">Baking bad</a>
                        </small>
                    </p>
                    <input type="text" placeholder="Baker tz1, KT1..." className="tnull" onChange={this.updateReceiver} value={this.state.receiver}/>
                    
                    {!this.props.selectedKeystore.keystore.ciphertext &&
                        <div>
                            <input
                                placeholder="Keystore file"
                                type="file" className="dnone"
                                ref={this.hiddenFileInput}
                                onChange={(e) => this.onFileHandler(e)}
                            />
                            {!this.state.encryptedWallet?
                                <div className="submit" onClick={this.handleUpload}>
                                    Upload wallet
                                </div>
                            :
                                <div>
                                    <p className="tcenter">
                                        <small className="tsuccess">
                                            <strong>{this.state.fileName}</strong> selected
                                        </small>
                                    </p>
                                    <p className="tcenter">
                                        <a className="pointer" href="#upload" onClick={() => {this.setState({encryptedWallet: null})}}>
                                            <small>Click to change file</small>
                                        </a>
                                    </p>
                                </div>
                            }
                        </div>
                    }
                    <input type="password" placeholder="Password" onChange={this.updatePswd}/>
                    <div className="gauge">Fees ~0.0001ꜩ</div>
                    {this.state.pending?
                            <p className="tcenter"><strong><FaCircleNotch className="spinner"/></strong> <small>Building delegation</small></p>
                        :
                        <div>
                            <div className="submit dashboard" onClick={() => {this.buildDelegation()}}>
                                Delegate funds
                            </div>
                            <div className="cancel" onClick={() => {this.props.updateDisplay('account')}}>
                                Cancel
                            </div>
                        </div>
                    }
                </div>
                {this.state.confirmation &&
                    <Confirm selectedKeystore={this.props.selectedKeystore}
                            selectedCurrency={this.state.selectedCurrency}
                            receiver={this.state.receiver}
                            keystores={this.props.keystores}
                            cancelOp={this.cancelOp}
                            sendTransfer={this.sendDelegation}
                            pending={this.state.pending}
                            error={this.state.error}/>
                }
                {this.state.success &&
                    <Publishing opHash={this.state.opHash} published={this.state.published}/>
                }
            </div>
        </React.Fragment>
  );
}


export default Delegate;